/* _______________________Colors_______________________ */

/* :root {
  --as_black: #000;
  --as_black_varient: #000000a6;
  --as_white: #fdfdfd;
  --as_white-varient: #fdfdfd63;
  --as_yellow: #ffd205;
  --as_primary: #c74e70;
  --as_secondary: #5c2945;
  --as_secondary_varient: #5c2945f2;
  --as_gray: #f2faff;
  --as_box_shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --as_rounded: 0.3rem;
} */
.as_h1 {
  font-size: 2rem;
}
.as_what_text {
  font-size: 16px;
}
.as_h2 {
  font-size: 18px;
}
.as_h4 {
  font-size: 1rem;
}
.as_p {
  font-size: 0.9rem;
}
.as_button {
  font-size: 1.3rem;
  font-weight: 600;
  outline: none;
  border: none;
}
.as_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
/* .as_family_time_today_wrap {
  background: #fbf2f5;
} */

.as_family_time_today_img {
  padding: 4rem 0 0 0;
}
.as_input {
  outline: none;
  border: 1px solid #e3e3e3;
}

/* _______________________Basic_Styles_______________________ */

.as_layout {
  background-color: var(--as_white);
  box-sizing: border-box;
  text-decoration: none;
}
.as_container {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.as_title_banner {
  flex-direction: column;
  background: var(--as_primary);
  padding: 0.7rem 0rem;
  min-height: 50px;
  color: var(--as_white);
  gap: 5px;
}
.as_title_banner .as_headingbar_title,
.as_title_banner .as_headingbar_desc {
  color: var(--as_white);
}
.as_offere_banner {
  background-color: #d94040;
  text-align: center;
  color: var(--as_white);
  padding: 10px 10px;
  gap: 0.3rem;
}
.as_offere_banner .as_ancher {
  font-size: 1rem;
  font-weight: 600;
  color: var(--as_white);
  background: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  text-underline-offset: 3px;
}

/* _______________________Media_Queries_______________________ */

@media screen and (max-width: 1024px) {
  .as_h1 {
    font-size: 1.5rem;
  }
  .as_h2 {
    font-size: 1.5rem;
  }
  .as_h4 {
    font-size: 0.9rem;
  }
  .as_p {
    font-size: 0.9rem;
  }
  .as_button {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 600px) {
  .as_h1 {
    font-size: 1.2rem;
  }
  .as_h2 {
    font-size: 1.1rem;
  }
  .as_h4 {
    font-size: 0.8rem;
  }
  .as_p {
    font-size: 0.8rem;
  }
  .as_button {
    font-size: 1rem;
    font-weight: 600;
  }
}
