.faqs-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.faqs-page h1 {
  color: #5c2945;
  margin: 50px 0;
}
.faqs-wrapper {
  margin-bottom: 100px;
}
