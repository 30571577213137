@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@200;300;400;500&display=swap');

:root {
  --as_black: #000;
  --as_light: #555;
  --as_black_varient: #000000a6;
  --as_white: #fdfdfd;
  --as_white-varient: #fdfdfd63;
  --as_yellow: #ffd205;
  --as_primary: #c74e70;
  --as_secondary: #5c2945;
  --as_secondary_varient: #5c2945f2;
  --as_gray: #f2faff;
  /* --as_box_shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1); */
  /* --as_box_shadow: 0 25px 50px -12px rgb(0 0 0 / 0.5); */
  --as_rounded: 0.3rem;
  --as_transition: 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.as_home_header_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 39vw;
}
.as_home_header {
  width: 100%;
  height: 100%;
}
.as_home_header_slides {
  width: 100%;
  height: calc(100% - 100px);
  /* box-shadow: var(--as_box_shadow); */
  
}
.as_home_header_slides_tab,
.as_home_header_slides_mobile {
  display: none !important;
}
.as_home_header_slides_image_wrap {
  width: 100%;
  /* height: 100%;
  overflow: hidden; */
}
.as_home_header_slides_image {
  width: 100%;
  /* height: 100%;
  object-fit: fill; */
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.2);
 
}
.as_home_header_slides_content {
  width: 45%;
  min-height: 129px;
  background-color: var(--as_white);
  box-shadow: var(--as_box_shadow);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  top: 72%;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 20px;
}
.as_home_header_slides_content_title {
  font-family: 'Montserrat', sans-serif;
  font-size: 25px;
  font-weight: 800;
  text-align: left;
  color: var(--as_primary);
}
.as_home_header_slides_content_desc {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: left;
}
.as_home_header_slides_content_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: var(--as_rounded);
  text-decoration: none;
  transition: all var(--as_transition);
  border: 1px solid var(--as_secondary);
  background-color: var(--as_secondary);
  color: var(--as_white);
  width: fit-content;
}
/* .as_home_header_slides_content_button:hover {
  transition: all var(--as_transition);
} */
.as_home_header_slides_content_mobile {
  display: none;
}

@media screen and (min-width: 1200px) {
  .as_home_header_wrap {
      margin-bottom: 90px;
    }
}
@media screen and (max-width: 1200px) {
.as_home_header_slides_content{
  top: 70%
}
}

@media screen and (max-width: 1050px) {
  .as_home_header_slides_content {
    top: 68%
  }
    .as_home_header_wrap{
      margin-bottom: 90px;
    }

}

@media screen and (max-width: 900px) {
  .as_home_header_wrap {
    height: auto;
    margin-bottom: 0;
  }
  .as_home_header_slides {
    height: unset;
  }
  .as_home_header_slides_content {
    display: none;
  }
    .as_home_header_slides_image {
      box-shadow: none;
    }
  /* .as_home_header_slides_tab {
    display: flex;
  } */
  /* .as_home_header_slides_image_desktop {
    width: 100%;
    height: 100%;
    object-fit: fill;
  } */
}
@media screen and (max-width: 450px) {
  .as_home_header_slides {
    height: fit-content;
  }
  /* .as_home_header_slides {
    flex-direction: column;
  }
  .as_home_header_wrap {
    height: 100%;
  }
  .as_home_header_slides {
    height: 100%;
  } */
  .as_home_header_slides_image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}

@media screen and (max-width: 500px) {
  /* .as_home_header_slides_content_title {
    font-size: 18px;
  }
  .as_home_header_slides_content_desc {
    font-size: 12px;
  }
  .as_home_header_slides_content_button {
    font-size: 14px;
  } */
}
