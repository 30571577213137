.form-body {
  width: 100%;
  height: 750px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding: 40px 0;
  position: relative;
  height: 1250px;
}
.form-getit {
  height: 1250px;
  padding-top: unset !important;
}
.step-4-submission {
  display: none;
}
.kittype-box {
  width: 500px;
  /* margin-top: 50px; */
}
.kittype-box h1,
.CooperKits h1 {
  width: 100%;
  text-align: center;
  font-size: 22px;
  padding-bottom: 20px;
}
.sub-form {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-of-children {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;
  margin-top: 50px;
}

.no-of-children h3,
.email-main h3 {
  color: black;
  width: 28%;
  text-align: center;
  font-size: 25px;
  text-align: center;
  /* font-weight: 400; */
}

.box-types-main h3 {
  width: 70%;
  text-align: center;
  font-size: 25px;
}

.email-main h3,
.fullname-main h3 {
  width: 100%;
  text-align: center;
  font-size: 25px;
}

.no-of-children h5,
.box-types-main h5,
.email-main h5,
.fullname-main h5 {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 100;
  font-size: 18px;
}
.kittype-img {
  width: 100% !important;
}
.kittype-img div {
  width: 49%;
}
.no-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.no-option {
  width: 220px;
  height: 220px;
  border-radius: 20px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #90dae540;
}

.no-option h1 {
  color: #7b3d5d;
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.box-types-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.box-types {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
}

.box-type {
  width: 220px;
  height: 220px;
  border: 1px solid #fdf1eb;
  border-radius: 20px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #90dae540;
}
.plans-hover-details {
  font-size: 14px;
  color: #5c6b78;
  padding-bottom: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  border: 1px solid black;
  width: 320px;
  margin: auto;
  border: 1px solid #c4c4c4;
  border-radius: 0px 0px 20px 20px;
  border-top: unset;
}
.plans-hover-details svg {
  font-size: 24px;
  padding-left: 5px;
}
.box-type h1 {
  color: #601b3a;
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.form-buttons {
  margin-top: 20px;
}

.form-buttons button {
  margin: 0 10px;
}

.email-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.standard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  /* margin-top: 50px; */
  padding-top: 50px;
  position: relative;
}
.internationall-getit {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
}
.all-discount {
  margin: 30px 0px;
}
.plans-save {
  text-align: center;
  background-color: #f2b21c;
  padding: 10px;
  color: white;
  border-radius: 0px 0px 20px 20px;
  width: 95%;
  margin: auto;
  position: absolute;
  bottom: -24px;
  display: none;
}
.standard-boxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-start;
  margin-top: 60px;
  position: relative;
  /* margin-bottom: 20px; */
}
.homeschooling-link {
  top: -50px !important;
}
.gift-boxes-padding {
  padding-bottom: 50px;
}
.plansoffer-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 95%;
  align-items: center;
}
.getit-position {
  display: flex;
  /* width: 100%; */
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  text-align: left;
}
.plansoffer-homeschool {
  width: 95% !important;
}
.subheading-plans {
  padding-top: 10px;
  color: #c64f6f;
  font-size: 18px;
}
.standard-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 320px;
  /* height: 475px; */
  height: auto;
  margin: 0 10px;
  background: #f9f9f9;
  border-radius: 10px;
  text-align: left;
  position: relative;
}
.plans-hover-display {
  padding: 4px;
  text-align: left;
  width: 95%;
  border-top: 1px solid #c4c4c4;
}
/* .plans-hover-details:hover ~ .plans-hover-display {
    opacity: 1;
    transition: 0.6s;
} */
.plans-hover-display p {
  color: black !important;
}

.getit-discount {
  top: 0;
  left: 12px;
  position: absolute;
}
.offer-discount p {
  font-size: 14px;
  font-weight: bold;
  color: #c74e70;
}

.offer-discount i {
  font-size: 13px;
}

.offer-discount {
  padding: 5px 28px;
  text-align: center;
}
.free-shipping {
  font-size: 12px;
  color: black !important;
  text-align: left !important;
  padding-bottom: 10px;
}

.margin-bottom-freeShipping {
margin-bottom: 17px;
}
.getit-position {
  position: relative;
  /* padding-right: 10px; */
}
.getit-line {
  position: absolute;
  width: 96.33px;
  border: 1px solid #c74e70;
  transform: rotate(-4.76deg);
  height: 0px;
  top: 7px;
}
.getit-line-home {
  position: absolute;
  width: 40px;
  border: 1px solid #c74e70;
  transform: rotate(-21.76deg);
  height: 0px;
  top: 7px;
}
.plansoffer-display h3 {
  color: black;
  font-size: 16px;
  /* padding-bottom: 5px; */

  text-decoration: line-through;
}
.box-border {
  width: 95%;
  border-bottom: 0.5px solid #c4c4c4;
  padding-bottom: 5px;
}
.getit-holiday {
  color: black;
  font-size: 12px;
  /* padding-left: 10px; */
}

.product-card-image img {
  height: 100px;
}
.choose-plan-box {
  margin-bottom: 5px;
  font-size: 12px;
  color: black;
  text-align: left;
  padding: 8px 15px;
  width: 95%;
  display: none !important;
  border-radius: 8px;
  cursor: pointer;
}
.standard-box h1 {
  font-size: 18px;
}
.prepay-box {
  width: 95%;
  text-align: left;
  font-size: 12px;
}
.prepay-box span {
  text-decoration: line-through;
}
.prepay-color {
  color: #c64f6f;
  text-decoration: unset !important;
}
.standard-box h2 {
  color: #c64f6f;
  font-size: 18px;
  /* margin-bottom: 5px; */
  padding: 0px 5px;
}
.getit-position h2 {
  font-size: 18px !important;
}

.bonus-div {
  width: 70%;
  height: 25px;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  /* margin-bottom: 15px; */
  order: -1;
}

.bonus-paragraph {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #c64f6f;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  /* font-weight: bold; */
  font-size: 12px;
  border-radius: 3px;
}

.shipping-info {
  font-size: 12px;

  color: #5c6b78;
}
.shipping-info span {
  font-weight: bold !important;
  color: #c74e70 !important;
}
#shipping-info {
  width: 100%;
  border-top: 2px dotted #c4c4c4;
  border-radius: 100px;
  height: 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.internationall-p {
  position: absolute;
  right: 10px;
  font-size: 14px;
}
.box-title {
  color: #000000;
  font-size: 25px;
  /* width: 385px; */
  text-align: center;
  /* width: 406px; */
}
.homeschoolers-heading {
  font-size: 32px;
  color: #7b3d5d;
  font-weight: 400;
}
.poc-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.poc-img p {
  padding-right: 10px;
}
.boxes-h2,
.boxes-h2-gift {
  color: #7b3d5d;
  font-size: 20px;
  width: 540px;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;
}
.boxes-h2-gift {
  margin-top: 0px !important;
}
.boxes-h2,
.boxes-h2-homeschooling {
  color: #7b3d5d;
  font-size: 14px;
  width: 540px;
  text-align: center;
  margin-top: 30px;
}
.boxes-h2-homeschooling {
  padding: 30px 0px 20px 0px;
  margin-top: 0px !important;
}
.boxes-p {
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #1e2833;
  margin-top: 40px;
  padding-bottom: 10px;
  /* margin-top: 40px; */
}
.boxes-discount {
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #1e2833;
}

.boxes-email {
  font-size: 14px;
  padding: 20px 40px;
  color: #1e2833;
  text-align: center;
  font-weight: 400;
  width: 50%;
}
.upsell {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
}

.other-upsell-answer {
  width: 150px;
  height: 150px;
  border: 1px solid #601b3a;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.medallion {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
}

.other-medallion-answer {
  width: 150px;
  height: 150px;
  border: 1px solid #601b3a;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

#no-label span {
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 76px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  position: relative;
}
.getit-form {
  border-bottom: unset !important;

  display: none;
}
.shipping-info span {
  color: black;
  font-weight: 600;
}
.labelholiday-price {
  color: #f2b21c !important;
  text-decoration: unset !important;
}
.form-navbar-logo {
  left: 30px;
  position: absolute;
}

.slidebar {
  width: 600px;
  height: 45px;

  /* margin-left: 25%; */
}

.multiple-child-div {
  width: 460px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  border-radius: 4px;
  position: relative;
}

.input-container2 input {
  width: 60px;
  height: 40px;
  border: 1px solid #90dae5;
  border-radius: 6px;
  background-color: #90dae540;
  /* padding-left: 10px; */
  outline: none;
  color: #c64f6f;
  text-align: center;
  font-weight: bold;
}

.input-container2 {
  width: 60px;
  height: 40px;
  padding-right: 20px;
}

.international-link,
.international-link-gift {
  font-size: 14px;
  /* padding: 20px 40px 0px; */
  /* padding: 30px 0px; */
  color: #1e2833;
  text-align: center;
  font-weight: 400;
  width: 35%;
  padding: 20px 0px;
  position: absolute;
  top: -50px;
  right: 0px;
}
.international-link-gift-blue {
  color: #59bbe6;
  text-decoration: underline;
}
.international-link-gift {
  width: 55% !important;
}
.international-link a,
.international-link-gift a {
  color: #1193bb;
}

.multiple-child-div p {
  font-size: 18px;
  width: 270px;
  margin-left: 20px;
}

.error-message-2 {
  color: red;
  font-size: 12px;
  text-align: center;
  padding: 0 10px;
  width: 320px;
}

.square {
  width: 30px;
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  right: 90px;
  top: -15px;
}

.no-of-children button,
.box-types-main button,
.standard button {
  margin-top: 30px;
  width: 460px;
  height: 41px;
  background-color: #90dae5;
  outline: none;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.email-main button {
  width: 95%;
  margin-top: 30px;
  height: 41px;
  background-color: #90dae5;
  outline: none;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
}
.form-date {
  background: rgba(144, 218, 229, 0.25);
  border-radius: 6px;
  padding: 15px 0px;
  margin: auto;
  width: 91%;
  text-align: left;
  /* padding-left: 20px; */
  font-size: 18px;
  margin-bottom: 20px;

  border: 1px solid #90dae5;
  display: flex;
}
.fullname-main label {
  width: 92%;
  margin: auto;
  display: flex;
  padding-bottom: 5px;
  color: #5c2945;
}
.form-date::-webkit-calendar-picker-indicator {
  /* background-color: white; */
  color: #5c2945 !important;
  margin-right: 20px;
}
.form-date::-webkit-datetime-edit-month-field {
  color: #5c2945 !important;
  opacity: 0.5;
  margin-left: 20px;
}

.form-date::-webkit-datetime-edit-day-field {
  color: #5c2945 !important;
  opacity: 0.5;
}
.form-date::-webkit-datetime-edit-year-field {
  color: #5c2945 !important;
  opacity: 0.5;
}
.fullname-button {
  /* margin-top: 30px; */
  width: 92%;
  height: 41px;
  background-color: #90dae5;
  outline: none;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* margin-left: 0px; */
  /* margin-top: 10px;
    margin: auto; */
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 95%;
  border-radius: 6px;
  height: 60px;
}

.input-container3 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
  border-radius: 6px;
  height: 60px;
}

.middle-space {
  width: 10px;
}

.input-container4 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
  border-radius: 6px;
  height: 60px;
}

.input-container3:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #c64f6f;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #c64f6f;
}

.input-container4:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #c64f6f;
}

.input-container3 .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input-container4 .filled {
  transform: translate(0, 12px) scale(0.8);
}

#shipping-info-mobile {
  display: none;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input-container4 label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #c64f6f;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container3 label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #c64f6f;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #c64f6f;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container input {
  height: 64px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  padding: 20px 0 0 15px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 95%;
  border-radius: 10px;
  border: 1px solid #90dae5;
  background-color: #90dae540;
}

.input-container3 input {
  height: 64px;
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: none;
  font-size: 16px;
  padding: 20px 0 0 15px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 95%;
  border-radius: 10px;
  border: 1px solid #90dae5;
  background-color: #90dae540;
}

.input-container4 input {
  height: 64px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border: none;
  font-size: 16px;
  padding: 20px 0 0 15px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 95%;
  border-radius: 10px;
  border: 1px solid #90dae5;
  background-color: #90dae540;
}

.email-main {
  margin-top: 50px;
  width: 330px;
  align-items: center;
  width: 515px;
}

.fullname-main {
  width: 515px;
  margin-top: 50px;
  align-items: center;
}

.main-input-cotainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.agreement {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 320px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.agreement p {
  /* padding-left: 20px; */
  color: #1e2833;
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img.image-1 {
  width: 134px;
  top: 6px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 1;
  cursor: default;
  height: 10px;
  width: 10px;
  left: 5px;
  top: 10px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.container input:active {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.container input:focus {
  outline: none;
}

.container input::after {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.product-title {
  width: 100%;
  text-align: left;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 65%;
  padding-bottom: 10px;
}
.product-arrow svg {
  font-size: 30px;
}
.product-arrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #7b3d5d;
  color: white;
  padding: 10px;
  height: 35px;
  text-align: left;

  border-radius: 8px;
  margin-bottom: 10px;
  width: 95%;
  transition: 0.3s;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f4dee1;
  border: 1px solid #c74e70;

  -webkit-tap-highlight-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: default;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #f4dee1;

  -webkit-tap-highlight-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #c74e70;

  -webkit-tap-highlight-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: default;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;

  -webkit-tap-highlight-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: default;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;

  -webkit-tap-highlight-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: default;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  -webkit-tap-highlight-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: default;
}

.checkbox:active {
  -webkit-tap-highlight-color: transparent !important;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: default;
}

.checkbox::before {
  -webkit-tap-highlight-color: transparent !important;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: default;
}

.agreement-paragraph {
  /* margin-top: 30px; */
  font-size: 13px;
  width: 320px;
  text-align: left;
  font-weight: 400;
  color: #1e2833;
}

.agreement-paragraph a {
  color: #c64f6f;
}

.medallion-container {
  width: 1200px;
  height: 410px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0px !important;
  left: auto;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0px !important;
  right: auto;
}

.medallion-image {
  width: 30%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  /* background-color: red; */
  position: relative;
}

.tshirt-tabs {
  width: 100%;
  height: 40px;
  position: absolute;
  top: -40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.adult-p {
  padding: 20px 0px;
}
.homeschoolerLink {
  margin-top: 20px;
  color: #7b3d5d;
}

.tshirt-tab {
  width: 35%;
  height: 95%;
  background-color: #f6f7f8;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tshirt-tab p {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}

.tshirt-tab1 {
  border-top-left-radius: 10px;
}

.tshirt-tab2 {
  border-top-right-radius: 10px;
}

.tab1-swiper {
  width: 100%;
  height: 100%;
}

.tab-slider-img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.medallion-text {
  width: 70%;
  height: 100%;
  background-color: #f6f7f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.medallion-text h1 {
  font-size: 25px;
  color: #000000;
  width: 100%;
  margin-top: 0px;
  line-break: strict;
}

.medallion-text p {
  font-size: 18px;
  color: #1e2833;
  width: 75%;
  margin-top: 10px;
  margin-bottom: 5px;
  margin: 10px auto;
}

.medallion-text button {
  width: 50%;
  margin-top: 5px;
  height: 45px;
  border: none;
  outline: none;
  color: #5c6b78;
  font-size: 17px;
  font-weight: 400;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}

.t-shirt-header {
  margin-top: 50px;
  width: 40%;
  text-align: center;
}

.confirm-button {
  background-color: #90dae5;
  color: white !important;
}

button {
  cursor: pointer;
}

.form-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  justify-content: center;
  /* position: absolute; */
  bottom: 0;
}

.form-footer p {
  color: #000000;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0;
  text-align: center;
}

.form-footer p > a {
  color: #7b3d5d;
  text-decoration: none;
}

.checkout {
  width: 100%;
  height: 100%;
}
.go-back-box,
.go-back-box-kitt {
  background-color: unset !important;
  color: #000000 !important;
  /* border: unset !important ; */
  display: flex;
  align-items: center;
  border: 1px solid #90dae5 !important;
  padding: 0px 15px;
  /* margin: auto; */
  margin-top: 20px !important;
  padding: 12px 20px !important;
  border-radius: 8px !important;
  color: #90dae5 !important;
  width: 25% !important;
  text-align: center !important;
  justify-content: center;
  font-size: 16px;
  transition: 0.4s;
}
.go-back-box-kitt {
  width: 25% !important;
  margin: auto !important;
  margin-top: 20px !important;
}
.go-back-box:hover {
  background-color: #90dae5;
  color: white;
}
.go-back-box-plans,
.go-back-box-plans-home {
  width: 15% !important;
  background-color: unset !important;
  color: #000000 !important;
  /* border: unset !important ; */
  display: flex;
  align-items: center;
  border: 1px solid #90dae5 !important;
  padding: 0px 15px;
  margin: auto;
  margin-top: 20px !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  color: #90dae5 !important;

  text-align: center !important;
  justify-content: center;
  font-size: 16px;
}

.go-back-box-plans-home {
  width: 15% !important;
}
.goback-display {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  margin-top: 20px !important;
}

.upsell-container {
  width: 1200px;
}
.kitheight-text {
  width: 100%;
  margin: auto;
}
.kittype-button {
  /* margin: auto; */
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  color: white;
  background: #90dae5;
  border: none;
  font-size: 18px;
  border-radius: 5px;
}
.plans-active {
  background-color: #c64f6f !important;
  border: white !important;
  color: white !important;
}
.delivery-program {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 600px;
  text-align: center;
  padding-bottom: 30px;

  /* margin: 0px 50px; */
}
.delivery-program div {
  display: flex;
  width: 100%;
}
.delivery-program p {
  width: 55%;
  font-weight: 600;
  padding: 40px 0px 20px 0px;
  font-size: 20px;
  color: #7b3d5d;
}
.delivery-program label {
  width: 40%;
  color: #c64f6f;
  font-weight: 800;
  cursor: pointer;
  margin: 5px;
  text-align: center;
  padding: 15px 8px;
  background-color: white;
  border: 1px solid #c64f6f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding-left: 10px;
  font-size: 16px;
  /* padding-top: 2px; */
  cursor: pointer;
}
.delivery-program input {
  margin-right: 10px;
}
.check-id-transition {
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
  font-weight: 600;
  padding-top: 50px;
  display: none;
}

@media screen and (max-width: 1728px) {
  /* .medallion-container {
    width: 600px;
    height: 620px;
  } */
  .no-of-children h3 {
    color: black;
    width: 28%;
    text-align: center;
    font-size: 25px;
    text-align: center;
    /* font-weight: 400; */
  }

  .no-of-children h3 {
    width: 477px;
  }
}

@media screen and (max-width: 1440px) {
  .slidebar {
    width: 520px;
    height: 35px;
  }

  /* .medallion-container {
    width: 520px;
    height: 580px;
  } */
}

@media screen and (max-width: 1366px) {
  .form-body {
    height: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .medallion-container {
    width: 100%;
  }
  .upsell-container {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .slidebar {
    width: 370px;
    height: 29px;
  }
  .top-minus {
    top: -33px !important;
  }
  .medallion-container {
    width: 95%;
    height: 480px;
  }
  .upsell-container {
    width: 95%;
  }
  .medallion-text h1 {
    font-size: 25px;
  }

  .medallion-text p {
    font-size: 18px;
  }

  .medallion-text button {
    font-size: 18px;
  }
  .boxes-email {
    width: 90%;
  }
}

@media screen and (max-width: 998px) {
  .medallion-text {
    width: 100%;
  }

  .t-shirt-header {
    width: 50%;
  }

  .shipping-info {
    font-size: 14px;

    color: #5c6b78;
  }

  .plans-save {
    width: 97%;

    left: 0;
  }

  .plans-save {
    background-color: unset;
    padding: 10px;
    color: black;
    text-align: left;
    /* border-radius: 0px 0px 20px 20px; */
    width: 95%;
    margin: unset;
    position: relative;
    bottom: unset;
    display: none;
  }
  .plans-hover-details {
    display: none;
  }
  .form-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 76px;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    position: relative;
  }

  .form-navbar-logo {
    left: 30px;
    top: 0;
    position: unset;
  }

  .form-body {
    height: fit-content;
    padding-bottom: 650px;
  }
  .form-getit {
    padding-bottom: 50px;
  }
  .slidebar {
    width: 60%;
    height: 29px;
  }

    .offer-discount {
      text-align: left;
    }

  .agreement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 320px;
    margin-top: 20px;
  }

  .checkout {
    width: 100%;
    height: 100vh;
  }

  .image-1 {
    width: 144px !important;
  }

  .standard-box h1 {
    font-size: 20px;
    text-align: left;
    width: 100%;
    min-height: auto;
  }

  .standard-box h2 {
    font-size: 15px;
  }

  .medallion-container {
    flex-direction: column;
    height: 937px;
    width: 50%;
    margin-top: 90px;
  }
  .upsell-container {
    width: 50%;
  }
  .medallion-image {
    width: 100%;
    border-radius: 10px;
  }

  .medallion-text h1 {
    font-size: 22px;
  }

  .standard-boxes {
    grid-row-gap: 30px;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 50px;
    row-gap: 45px;
    width: 90%;
  }

  .standard-box {
    width: 100%;

    margin: 0px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .product-card-image {
    width: 100%;
    position: unset;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-left: 40px; */
    /* margin-left: -20px; */
  }

  .bonus-div {
    width: 187px;
    height: 32px;
    top: 0;
    right: -15px;
    /* transform: rotate(20deg); */
    /* position: absolute; */
    order: -1;
  }

  #shipping-info {
    border: 2px dotted #c4c4c4;
    width: 95%;
  }

  .product-title {
    width: 95%;
    text-align: ce;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding-left: 40px; */
    justify-content: space-evenly;
    height: 100%;
    padding-top: 5px;
  }

  .standard-box h2 {
    font-size: 18px;
  }

  #shipping-info {
    display: none;
  }

  .medallion-text button {
    width: 90%;
  }

  #shipping-info-mobile {
    /* padding: 0px 0 20px 0; */
    padding-top: 15px;
    margin-top: -10px;
    z-index: -1;
    text-align: center;
    border-bottom: 2px dotted #5c6b78;
    border-left: 2px dotted #5c6b78;
    border-right: 2px dotted #5c6b78;
    border-radius: 0 0 15px 15px;
    align-items: center;
    display: flex;
    display: none;
    justify-content: center;
    height: 50px;
  }
  /* .go-back-gift {
        width: 90% !important;
    } */
  .delivery-program label {
    width: 30%;
    font-size: 15px;
  }
  /* .delivery-program p {
        width: 28%;
    } */
  /* .go-back-gift {
        width: 90% !important;
    } */
  .go-back-box {
    width: 30% !important;
  }
  .go-back-box-plans,
  .go-back-box-plans-home {
    width: 20% !important;
  }
  .getit-discount {
    width: unset !important;
    top: 45px !important;
  }
  .plansoffer-display {
    width: 95%;
  }
  .plansoffer-homeschool {
    width: 95% !important;
  }
  .internationall-p {
    position: absolute;
    /* right: 10px; */
    left: 44px;
    font-size: 14px;
    top: 57px;
    /* padding: 20px 0px; */
  }
  .getit-form {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .medallion-container {
    flex-direction: column;
    height: 900px;
    width: 80%;
    margin-top: 100px;
  }
  .upsell-container {
    width: 80%;
  }
  .form-navbar-logo {
    /* top: 10px; */
    left: unset;
  }

  .form-header {
    height: 15vh;
    align-items: center;
    justify-content: space-around;
  }

  .slidebar {
    margin-bottom: 0px;
  }

  .form-footer {
    height: 100px;
    /* position: fixed; */
    /* bottom: 0; */
  }

  .bonus-paragraph {
    font-size: 10px;
  }

  .image-1 {
    width: 105px !important;
  }

  .box-title {
    color: #000000;
    font-size: 22px;
    width: 90%;
    text-align: center;
  }

  .boxes-h2 {
    font-size: 12px;
  }

  .input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    border-radius: 6px;
    height: 60px;
  }

  .agreement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 87%;
    margin-top: 20px;
  }

  /* .fullname-button {
    margin-bottom: 200px;
  } */

  .slidebar {
    width: 63%;
    height: fit-content;
  }

  .t-shirt-header {
    width: 95%;
  }

  .medallion-image {
    width: 100%;
    border-radius: 10px;
    height: 50%;
  }
  .delivery-program {
    flex-direction: column;
    width: 100%;
    /* margin: 0px 50px; */
  }
  .delivery-program label {
    width: 85%;
    font-size: 16px;
  }
  .delivery-program div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .delivery-program p {
    width: 100%;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .poc-img {
    padding-top: 20px;
  }
  .international-link-gift,
  .international-link {
    width: 100% !important;
  }
  .internationall-p {
    position: absolute;
    /* right: 10px; */
    left: 30px;
    font-size: 14px;
    top: 115px;
    /* padding: 20px 0px; */
  }
}

@media screen and (max-width: 600px) {
  .medallion-text {
    height: auto;
    padding-top: 20px;
  }
  .t-shirt-header {
    width: 95%;
    font-size: 25px;
  }

  .standard-box img {
    margin-top: 0px;

    padding-bottom: 0px;
  }

  #shipping-info-mobile {
    height: auto;
  }

  .shipping-info {
    font-size: 12px;

    color: #5c6b78;
  }

  .bonus-div {
    width: 150px;
    height: 20px;
    top: 0;
    right: -15px;
    /* transform: rotate(20deg); */
    /* position: absolute; */
    order: -1;
  }

  .standard-box h2 {
    color: #c64f6f;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .standard-box h1 {
    font-size: 15px;
    text-align: left;
    width: 90%;
    margin: 0px;
    min-height: unset;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .product-arrow {
    margin-top: 15px;
  }

  .standard-box {
    width: 100%;

    margin: 0px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .offer-discount p {
    font-size: 11px;
  }

    .offer-discount i {
      font-size: 10px;
    }
  .image-1 {
    width: 100px !important;
  }

  .middle-space {
    width: 5px;
  }

  .email-main h3,
  .fullname-main h3 {
    width: 100%;
    text-align: center;
  }

  .slidebar {
    width: 50%;
    height: unset;
  }

  .form-header {
    height: 130px;
    align-items: center;
    flex-direction: column;
  }

  .form-navbar-logo {
    top: 10px;
    left: unset;
  }
  .kittype-box h1,
  .CooperKits h1 {
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 22px;
    padding: 20px 0px;
  }

  /* .standard-box img {
    margin-top: -45px;
    width: 80%;
  } */

  .no-of-children h5,
  .box-types-main h5,
  .email-main h5,
  .fullname-main h5 {
    width: 90%;
    text-align: center;
    margin: 20px auto;
  }

  .box-types-main h3 {
    width: 100%;
    text-align: center;
  }

  .slidebar {
    width: 90%;
    height: unset;
  }

  .no-of-children button,
  .box-types-main button,
  .standard button {
    width: 90%;
    margin-top: 25px;
  }
  .email-main button {
    width: 90%;
  }
  .no-option {
    width: 150px;
    height: 150px;
  }

  .no-option img {
    width: 100px;
  }

  .no-option h1 {
    font-size: 20px;
  }

  .multiple-child-div {
    width: 90%;
    height: 60px;
  }

  .multiple-child-div p {
    font-size: 18px;
    width: 71%;
    margin: 0;
  }

  .square {
    width: 20px;
    height: 20px;

    top: -10px;
  }

  .input-container2 {
    padding-right: 0px;
  }

  .input-container3 input,
  .input-container4 input {
    width: 90%;
    height: 40px;
  }

  .input-container2 input {
    width: 51px;
    height: 35px;
  }

  .box-type {
    width: 150px;
    height: 150px;
  }

  .box-type img {
    width: 100px;
  }

  .box-type h1 {
    font-size: 20px;
  }

  .form-body {
    padding: 0px;
  }

  .image-1 {
    width: 100px !important;
  }
  .check-id-transition {
    padding-top: 0px;
  }

  /* .standard {
    margin-bottom: 60px;
  } */

  .boxes-h2 {
    font-size: 12px;
    width: 90%;
  }

  .boxes-p {
    width: 90%;
  }

  /* .no-of-children {
    margin: 20px 0;
  } */

  .box-types-main {
    /* margin-bottom: 206px; */
    width: 100%;
    margin-top: 20px;
  }

  .email-main {
    margin-bottom: 0px;
    /* margin-top: 20px; */
    align-items: center;
    width: 100%;
    /* height: 74vh; */
  }

  .medallion-container {
    width: 95%;
    height: 820px;
    /* margin-bottom: 201px; */
    flex-direction: column;
    margin-top: 0px;
  }
  .form-body {
    height: fit-content;
    padding-bottom: 250px;
  }
  .form-getit {
    padding-bottom: 50px;
  }
  .medallion-container img {
    width: 100%;
    height: 100%;
  }
  .upsell-container {
    width: 95%;
  }
  .medallion-text h1 {
    font-size: 20px;
  }

  .medallion-text p {
    font-size: 15px;
    width: 90%;
  }

  .no-of-children h3 {
    width: 95%;
    padding: 0 20px;
  }

  .no-options,
  .box-types {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
    height: 408px;
  }

  .no-option,
  .box-type {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .fullname-main {
    width: 100%;
  }

  .input-container3,
  .input-container4 {
    width: 50%;
  }

  .fullname-button {
    margin-top: 30px;
    width: 95%;
    height: 41px;
    background-color: #90dae5;
    outline: none;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-weight: 400;
  }

  .main-input-cotainer {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .boxes-h2,
  .boxes-h2-gift {
    font-size: 12px;
    width: 80%;
  }

  .kittype-box h1,
  .CooperKits h1 {
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 22px;
    padding: 20px 0px;
  }

  .kittype-img {
    margin: auto;
    row-gap: 40px;
  }
  .kittype-img div {
    padding-top: 20px;
    margin-top: 20px;
  }
  .kittype-img div {
    width: 90%;
  }
  /* .name-input .homeschoolerLink {
    padding-bottom: 300px !important;
  } */
  .kitheight-text {
    padding-top: 100px;
    width: 90%;
    margin: auto;
  }
  .kittype-button {
    /* margin: auto; */
    margin-top: 80px !important;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .kittype-back {
    padding-left: 15px !important;
  }
  .check-id-transition {
    display: block;
    padding-bottom: 10px;
  }
  .boxes-h2-homeschooling {
    font-size: 12px;
    width: 90%;
  }
  .kittype-box {
    width: 100%;
    margin: auto;
  }
  .go-back-gift {
    width: 45% !important;
  }
  .go-back-box,
  .go-back-box-plans,
  .go-back-box-plans-home {
    width: 35% !important;
  }
  .go-back-box-kitt {
    width: 40% !important;
  }
  .getit-form img {
    width: 50%;
  }
  .getit-discount {
    top: 0 !important;
    width: 40px !important;
    left: -20px !important;
  }
  .getit-line {
    width: 75px;
  }
  .plansoffer-display,
  .plansoffer-homeschool {
    width: 95% !important;
  }
  .internationall-p {
    position: absolute;
    text-align: center;
    font-size: 14px;
    top: 66px;
  }
  .product-card-image {
    width: 90%;
    margin-top: 10px;
  }
  .product-title {
    width: 95%;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
  }
  .plansoffer-display {
    border-bottom: unset;
  }
  .choose-plan-box {
    margin-bottom: 5px;
    font-size: 12px;
    color: black;
  }

  .plansoffer-display {
    flex-direction: row;
    /* justify-content: start !important; */
    align-items: center;
    text-align: left;
  }
  .getit-holiday {
    padding-left: 0px;
  }
  .international-link {
    top: 50px;
  }
}

@media screen and (max-width: 380px) {
  .standard-boxes {
    width: 80%;
  }
  .international-link {
    top: -55px !important;
  }
  .standard-box img {
    margin-top: 0px;

    object-fit: contain;
  }

  .boxes-p {
    width: 85%;
  }

  .no-of-children button,
  .box-types-main button,
  .email-main button,
  .standard button {
    width: 85%;
    margin-top: 25px;
  }
}
.product_card_top {
  background-color: #c64f6f;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 10px 10px 0 0;
  font-size: 18px;
  padding: 10px 0;
}

.product_card_images {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.product_card_images img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.product_card_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 115px;
}
.product_price {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
}
.product_kit {
  font-size: 20px;
  font-weight: 900;
}
/* .product_discount_price {
  color: gray;
} */
.product_card_points {
  padding: 40px 15px 40px 28px;
  font-size: 14px;
  line-height: 18px;
  height: 170px;
}
.product_card_points ul li {
  margin-bottom: 10px;
}
.product_card_select_button {
  border-radius: 7px;
  background-color: #7b3d5d;
  color: white;
  margin: 15px 40px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
}
.product_card_bottom_text {
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}
.product_card_max_color {
  color: #c64f6f;
  text-align: center;
  margin-top: 7px;
  font-weight: bold;
}
.product_card_price_font {
  font-size: 30px;
}

/* .standard-boxes div:first-child .standard-box {
  padding-top: 41px;
} */

.standard-boxes-top-pad {
  padding-top: 41px;
} 

.list_discount_value {
  color: #c74e70;
}

.box-title_subheading-plans {
  color: #a7a4a4;
  margin: 7px;
}
