.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.as_container.as_main_slider_wrap {
  flex-direction: column;
  background-color: var(--as_gray);
  width: 500px;
  padding: 1rem 0;
}
.as_family_time_today_wrap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.as_family_time_today_wrap .as_title {
  color: var(--as_secondary);
  font-size: 1.2rem;
}
.swiper {
  width: 100%;
}
.as_mySwiper2 {
  width: 100%;
  height: 300px;
}
.as_mySwiper {
  height: 25%;
  box-sizing: border-box;
  padding: 1rem 0;
}

.as_mySwiper .swiper-slide {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.as_mySwiper .swiper-slide-thumb-active,
.as_mySwiper2 .swiper-slide-thumb-active {
  border: 1px solid var(--as_primary) !important;
  border-radius: var(--as_rounded);
}

.as_mySwiper2 .as_slider_img {
  display: block;
  width: 65%;
  border-radius: var(--as_rounded);
  object-fit: cover;
}
.as_mySwiper .as_slider_img {
  display: block;
  width: 100%;
  border-radius: var(--as_rounded);
  object-fit: cover;
  background-color: var(--as_gray);
}
.as_mySwiper2 .swiper-button-next::after,
.as_mySwiper2 .swiper-button-prev::after {
  color: var(--as_primary);
}
.as_mySwiper2 .swiper-button-next,
.as_mySwiper2 .swiper-button-prev {
  /* background: #c74e707d; */
  width: 40px;
}

.as_mySwiper .swiper-wrapper {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.as_slides_details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  box-sizing: border-box;
  border: none;
  outline: none;
  text-decoration: none;
}
.as_slider_detail_heading {
  display: none;
}
.as_slides_details .as_slide_detail_wrap {
  width: 500px;
}
.as_slides_details .as_slide_detail_wrap .as_image_grid_wrap .as_item {
  cursor: pointer;
  width: 65px !important;
  margin-right: 0px !important;
  border: 1px solid var(--as_gray);
}
.as_slides_details .as_slide_detail_wrap .as_details {
  width: 75%;
  display: flex;
  min-height: 110px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.as_slides_details .as_slide_detail_wrap .as_details .as_detail_heading {
  color: var(--as_secondary);
}
.as_slides_details .as_slide_detail_wrap .as_details .as_detail_heading_2,
.as_slides_details .as_slide_detail_wrap .as_details .as_detail_desc {
  color: var(--as_black_varient);
}
.as_slides_details .as_slide_detail_wrap .as_details .as_detail_desc {
  font-size: 0.8rem;
}
.as_detail_title {
  font-weight: 700;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: var(--as_secondary);
}
.as_detail_desc_2 {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--as_light);
}
@media screen and (max-width: 1024px) {
  .as_slides_details .as_slide_detail_wrap .as_image_grid_wrap {
    justify-content: center;
    align-items: center;
  }
  .as_slides_details .as_slide_detail_wrap .as_details .as_detail_heading {
    display: none;
  }
  .as_slider_detail_heading {
    display: block;
    color: var(--as_secondary);
  }
}
@media screen and (max-width: 500px) {
  .as_slides_details .as_slide_detail_wrap {
    flex-basis: 100%;
  }
  .as_slides_details {
    padding: 1rem;
  }
  .as_image_grid_wrap .swiper-wrapper {
    align-items: center;
    justify-content: center;
  }
  .as_slides_details .as_slide_detail_wrap .as_details {
    width: 100%;
  }
  .as_mySwiper2 {
    height: auto;
  }
  .as_slides_details .as_slide_detail_wrap .as_image_grid_wrap .as_item {
    width: 13% !important;
  }
}
