@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Clarendon-Bold';
    src: url('./Components/Shared//Assets/Fonts/clarendon-bold/Clarendon-Bold.ttf')
        format('truetype');
}

@font-face {
    font-family: 'Maroon';
    src: url('./Components/Shared//Assets/Fonts/maroonedonmars_bb/MaroonedOnMars_BB/MaroonedOnMarsBB.ttf')
        format('truetype');
}

@font-face {
    font-family: 'Uniform';
    src: url('./Components/Shared//Assets/Fonts/Uniform/Uniform Medium.ttf')
        format('truetype');
}

@font-face {
    font-family: 'Legend';
    src: url('./Components/Shared/Assets/Fonts/legend-bold.ttf')
        format('truetype');
}

code {
    font-family: 'Poppins';
}

* {
    margin: 0;
    padding: 0;
}

#root {
    overflow-x: hidden;
}

:root {
    --swiper-theme-color: #5c2945 !important;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f7f8;
    overflow-x: hidden !important;
    font-family: -apple-system, 'Uniform';
}

.main-wrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}

.load-spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 9999999999999999999999999999999;
}

.load-spinner2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
    z-index: 9999999999999999999999999999999;
}

.load-spinner2 h1 {
    font-size: 50px !important;
    width: fit-content !important;
    margin-bottom: 30px;
}

.news-letter-thankyou {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.news-letter-thankyou h1 {
    color: #5c2945;
}

.news-letter-thankyou a {
    text-decoration: none;
    color: white;
    background-color: rgb(140, 56, 75);
    padding: 10px 20px;
    border: none;
    margin-top: 30px;
}

.annual-order {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
}

.annual-order h1 {
    align-self: flex-start;
    margin: 20px 0;
    color: #8c384b;
}

/* #inf_form_d660f40b78ee9090c04616b3971d18c9 {
  width: fit-content !important;
  margin: 50px auto;
}

.infusion-field {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.infusion-field input {
  width: 90%;
  padding: 0 5%;
  height: 40px;
  border-radius: 10px;
  outline: none;
  border: 1px solid rgb(144, 218, 229);
  background-color: #90dae540;

}

.infusion-field label {
  margin-bottom: 10px;
  color: #5c2945;
}

.infusion-field input[type=checkbox] {
  width: 15px;
}

.infusion-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.infusion-checkbox label {
  margin-left: 5px;
  margin-bottom: 0;
}

.infusion-submit {
  width: fit-content;
  margin: 0 auto;
}

#inf_custom_GiftMessage,
#inf_custom_OrderNotes {
  width: 90%;
  padding: 10px 5%;

  outline: none;
  border: 1px solid rgb(144, 218, 229);
  resize: none;
  border-radius: 10px;
  background-color: #90dae540;
}

.infusion-submit button {
  padding: 12px 20px;
  border-radius: 10px;
  border: none;
  background-color: #5c2945;
  color: white;
  font-size: 18px;
} */

.cookie-link {
    /* text-decoration: none; */
    color: #90dae5;
}

.cookie-paragraph {
    width: 90%;
}

.cookie p {
    width: 95%;
    margin-left: 15px;
}

.cookie div {
    margin: 10px auto !important;
}

.music-sound {
    max-width: 1200px;
    margin: 50px auto 30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

figure#attachment_8074 {
    width: 50%;
    margin: 0 auto;
}

.music-sound h1,
.music-sound h2,
.music-sound h3 {
    color: #5c2945;
}

.music-sound h2 {
    margin: 20px 0;
}

img.size-full.wp-image-8074 {
    width: 100%;
}

.music-sound iframe {
    width: 50%;
}

.footer-popup #inf_field_Email {
    height: 20px !important;
    padding-left: 10px;
}

textarea#inf_custom_OrderNotes,
textarea#inf_custom_GiftMessage,
textarea#inf_custom_Bonus50CardShippingInfo,
textarea#inf_custom_Bonus50GiftNote {
    resize: none;
    padding: 5px;
    border: 1px solid #90dae5;
    background-color: #f6f7f8;
    border-radius: 10px;
    outline: none;
}

input#inf_field_Email {
    border: 1px solid #90dae5;
    background-color: #f6f7f8;
    border-radius: 10px;
}

input#inf_custom_Child1,
input#inf_custom_Child2,
input#inf_custom_Child3,
input#inf_custom_Child4,
input#inf_custom_Child5,
input#inf_custom_Child6,
input#inf_custom_Child7,
input#inf_custom_Age8Birthdate,
input#inf_custom_Age9Birthdate,
input#inf_custom_Age10Birthdate,
input#inf_custom_Age11Birthdate,
input#inf_custom_Age12aboveBirthdate,
input#inf_custom_ShippingEmail,
input#inf_custom_Bonus50CardRecipient,
input#inf_custom_Bonus50CardRecipientEmail {
    border: 1px solid #90dae5;
    background-color: #f6f7f8;
    border-radius: 10px;
    height: 30px;
    padding: 5px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@media screen and (max-width: 998px) {
    .music-sound iframe {
        width: 90%;
    }

    .cookie {
        flex-direction: column;
        z-index: 999999 !important;
        padding: 5px 0 !important;
    }

    .cookie button {
        margin: 10px 0 !important;
    }

    .cookie div {
        margin: 0 !important;
    }

    .annual-order img {
        width: 100%;
    }

    .cookie {
        flex-direction: column;
    }

    .cookie div {
        flex: unset !important;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .cookie p {
        width: 95%;
        margin-left: 0;
    }
}
