.contact-form {
  width: 100%;
  height: 600px;
  background-color: #90dae540;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding: 100px 0; */
}

.contact-form-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contant-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.contant-info h1 {
  color: #601b3a;
  font-size: 41px;
  margin-bottom: 10px;
}

.contant-info span {
  color: #5c6b78;
  font-size: 31px;
}

.contact-label {
  background-color: white;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.21);
  padding: 10px;
  border-radius: 7px;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label-icon {
  width: 30px;
  height: 30px;
  /* background-color: #c4c4c4; */
}

.contact-label p {
  margin-left: 15px;
  color: black;
}

.wbg {
  width: 100%;
  height: 150px;
  background-color: white;
}

.form-contact {
  height: 560px;
  width: 380px;
  background-color: white;
  border-radius: 40px;
  margin-top: 175px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.21);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
}

.form-contact h1 {
  color: #5c6b78;
  font-size: 31px;
  margin-bottom: 20px;
}

.contact-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-contact label {
  color: #5c6b78;
  font-size: 12px;
  margin-bottom: 5px;
}

.contact-input input {
  width: 100% !important;
  height: 40px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  outline: none !important;
  border-bottom: 1px solid #000000 !important;
  background-color: unset !important;
  border-radius: unset !important;
}

.contact-input textarea {
  width: 100%;
  height: 200px;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-bottom: 1px solid #000000;
  resize: none;
}

.form-contact button {
  width: 100%;
  height: 40px;
  background-color: #90dae5;
  border: none;
  outline: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 15px;
}

.thankyou-message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.thankyou-message h1 {
  color: #601b3a;
}

.thankyou-message h3 {
  font-weight: 400;
  color: #5c6b78;
}

@media screen and (max-width: 998px) {
  .contact-form-main {
    flex-direction: column;
  }

  .contant-info {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .form-contact {
    height: 660px;
    width: 310px;
    margin-top: 35px;
  }

  .contact-input {
    width: 100%;
    margin: 10px 0;
  }

  .contant-info h1 {
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .wbg {
    height: 430px;
  }
}