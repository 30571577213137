.main-press {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
}

.press-column {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start !important;
}

.press-title {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    color: #8c384b;
}

.press-column h1 {
    color: #8c384b;
    text-align: left;
    margin: 30px 0;
}

.press-column a {
    margin: 30px 0;
}

.press-column a>img {
    width: 80%;
}

.press-contact-links img {
    width: 30px !important;
    margin-right: 10px;

}

.press-contact-links {
    display: flex;
    flex-direction: row;
    align-items: center;

}

@media screen and (max-width:600px) {
    .main-press {
        flex-direction: column;
        align-items: center;
    }

    .press-column h1 {
        color: #8c384b;
        text-align: center;
    }

    .press-column a>img {
        width: 100%;
    }

}