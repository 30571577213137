.affiliate-program {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 0;

}

.affiliate-program h1 {
    color: #8c384b;
    font-size: 21px;
}

.affiliate-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 450px;
    margin-top: 30px;
}

.affiliate-images-1 {
    width: 60%;
}

.affiliate-images-2 {
    width: 35%;
}

.affiliate-program-center-title {
    text-align: center;
    margin-top: 30px;
}

.affiliate-program p {
    margin-top: 10px;
    color: #5d5d5d;
    padding: 0 20px;
}

.affiliate-list {
    margin-top: 10px;
    padding: 0 45px;
}

.affiliate-list li {
    color: #5d5d5d;
}

.affiliate-program a {
    text-align: center;
    text-decoration: none;
    background-color: rgb(140, 56, 75) !important;
    width: fit-content;
    margin: 0 auto;
    color: white;
    font-size: 18px;
    padding: 12px 15px;
    margin-top: 30px;
}

.affiliate-main-title {
    padding: 0 20px;
}

.affiliate-overview {
    width: 100%;
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;
    padding: 25px 0;
    margin-top: 25px;
    background-color: #f5f5f5;
}

.overview h1 {
    color: #5d5d5d;
    font-size: 18px;
}

.overview ul {
    padding-left: 30px;
    margin-top: 10px;
}

.overview li {
    color: #5d5d5d;
}

.affiliate-testimonials {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}

.affiliate-testimonials-small {
    width: 300px;
    height: 320px;
    background-color: rgb(93, 105, 124) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px;
}

.affiliate-testimonials-medium {
    margin: 0 15px;
    width: 400px;
    height: 420px;
    background-color: rgb(20, 41, 84) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.affiliate-testimonials-name {
    color: white !important;
    border-bottom: 1px solid rgb(210, 33, 21);
    width: 100%;
    text-align: center;
    align-items: center;
    padding-bottom: 20px;
    font-size: 21px;
    padding: 20px 0px;

}

.affiliate-testimonials-text {
    color: rgb(246, 247, 249) !important;
    position: relative;
    padding: 0 20px;
    line-height: 30px;
    font-size: 20px;


}

.affiliate-testimonials-down-quotes {
    width: 25px;
    position: absolute;
    margin-left: 20px;
    color: #5B6A88 !important;

}

.affiliate-testimonials-up-quotes {
    width: 25px;
    transform: rotate(180deg);
    margin-right: 5px;

}

@media screen and (max-width:998px) {
    .affiliate-images {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;
        margin-top: 30px;
        align-items: center;
    }

    .affiliate-program p {
        text-align: center;
    }

    .affiliate-overview {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .overview {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
    }



    .affiliate-testimonials {
        flex-direction: column;
        align-items: center;
    }

    .affiliate-testimonials-medium {
        margin: 10px 0;
    }

    .affiliate-images-1 {
        width: 90%;
    }

    .affiliate-images-2 {
        width: 45%;
    }

}

@media screen and (max-width:600px) {
    .affiliate-testimonials-medium {
        margin: 10px 0;
        width: 95%;
    }

}