.friends_main {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.friends_main img {
    max-width: 1200px;
    width: 100%;
}

.friends_texts {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 30px;
}

.friends_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33%;
    height: 100%;
    padding: 0 10px;

    justify-content: flex-start;
}

.friends_text h1 {
    font-size: 25px;
    line-height: 50px;
}

.friends_text p {
    color: #5d5d5d;
}

.friends_text p > span {
    color: #e55c3b;
    font-weight: bold;
}

.friends_text a {
    background-color: #e55c3b;
    text-decoration: none;
    color: white;
    font-size: 27px;
    padding: 5px 20px;
    font-family: 'Legend';
    border-radius: 8px;
    font-weight: bold;
    margin-top: 15px;
    transition: 0.3s;
}
.friends_text a:hover {
    background-color: black;
}
@media screen and (max-width: 1200px) {
    .friends_texts {
        padding: 0 20px;
    }
}

@media screen and (max-width: 1200px) {
    .friends_texts {
        flex-direction: column;
        height: fit-content;
        margin-top: 0;
    }

    .friends_texts h1 {
        margin-top: 30px;
    }

    .friends_text {
        align-items: center;
        text-align: center;
        width: 315px;
    }
}
