.landing-tabs {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.full-landing {
    padding: 20px 0px;
}
.landing-tabs li {
    list-style: none;
    background-color: #e3f6f8;
    border-radius: 8px;

    border: 3px solid #e3f6f8;
    padding: 10px 60px;
    margin: 0px 10px;
    cursor: pointer;
    transition: 0.3s;
}
.landing-tabs div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landing-tabs li:hover {
    background: #7d4363;
    color: white;
}
.active-landing {
    border: 3px solid #7d4363 !important;
}
.landing-tabs p {
    padding: 5px 15px;
    font-size: 12px;
    background-color: #7d4363;
    color: white;
    opacity: 0;
}
.selected-landing {
    padding: 5px 15px;
    border-radius: 8px 8px 0px 0px;
    font-size: 12px;
    background-color: #7d4363;
    color: white;

    opacity: 1 !important;
}
.landing-box {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;

    padding-bottom: 50px;
    border-bottom: 1px solid #ececec;
    margin-top: 50px;
}
.conference-landing {
    grid-template-columns: repeat(4, 1fr) !important;
    position: relative;
}
.landing-value {
    position: absolute;
    top: -30px;
    left: 0px;
    width: 130px;
}
.landingBadge {
    height: unset !important;
}
.withCode h3,
.withCode p {
    width: 100% !important;
    text-align: center;
}
.withCode .badge-yellow {
    width: 40% !important;
    margin: auto;
}
.withCode h1 {
    text-align: center;
}
.code-cart h1 {
    margin: unset !important;
    text-align: center;
}
.ref {
    position: absolute;
    top: 0;
    right: 20px;

    height: 30px !important;
}
.landing-box a {
    background: rgba(191, 191, 191, 0.1);
    border-radius: 10px;
    position: relative;
}
.self-checkout {
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}
.landing-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin: auto;
}
.landing-message svg {
    font-size: 54px;
    margin: 0px 20px;
}
.landing-badge {
    text-align: left;
}
.landing-badge span {
    font-size: 12px;
}
.art-display {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(191, 191, 191, 0.1);
}
.landing-badge h3 {
    width: 60%;

    font-size: 24px;
    padding-bottom: 10px;
}
.landing-badge h1 {
    display: flex;
    flex-direction: column;
    width: 130px;
    font-size: 24px;
    margin: auto;
    background-color: #faa019;
}
.badge-yellow {
    background-color: #faa019;

    font-size: 14px;
    padding: 5px;
    width: 260px;
}
.landing-title span {
    font-size: 14px;

    color: #7b3d5d;
}
.landing-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 50px;
}
.landig-arrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #7b3d5d;
    color: white;
    padding: 10px;
    height: 35px;
    text-align: left;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: 0.3s;
}
.landing-box a:hover .landig-arrow {
    background: #faa019;
}
.landig-arrow svg {
    font-size: 20px;
}
.landing-box h1 {
    font-size: 16px;
}
.landing-badge div {
    margin: 10px 0px;
}
.landing-box a {
    text-decoration: none;
}
.landing-box p {
    font-size: 14px;
    padding: 5px;
    font-weight: 600;
    color: black;
}

.landing-box p:first-child {
    border-bottom: 0.5px solid #c4c4c4;
}
.landing-box p:nth-last-child(1) {
    border-top: 0.5px solid #c4c4c4;
    border-bottom: unset;
}
.landing-box img,
.otherbox-display img {
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 20px 0px;
    height: 120px;
    object-fit: cover;
}
.change-box {
    height: 90px !important;
}
.otherbox-display {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.other-box {
    text-decoration: unset;
    width: 30%;
    margin: 5px;
    text-align: center;
}

.otherbox-display p {
    font-size: 14px;
    padding: 5px;
    color: black;
}
.otherBox-title {
    text-align: center;
    padding: 20px 0px;
}

.otherbox-display svg {
    font-size: 20px;
}
.other-box:hover .landig-arrow {
    background: #faa019;
}
.otherbox-display p:nth-last-child(1) {
    font-weight: bold;
}
.otherbox-display h1 {
    width: 90%;
    font-size: 16px;
}
.landin-description {
    height: 120px;
}
.landin-description p {
    border: unset !important;
}
.landing-box span {
    color: #7b3d5d;
}
@media screen and (max-width: 1200px) {
    .full-landing {
        width: 90% !important;
        margin: auto;
    }
    .landing-tabs li {
        padding: 10px 35px;
    }
    .conference-landing {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media screen and (max-width: 700px) {
    .landing-box {
        grid-template-columns: repeat(1, 1fr);
    }
    .landing-tabs {
        flex-direction: column;
    }
    .landing-message {
        width: 100%;
    }
    .other-box {
        width: 45%;
    }
    .otherbox-display h1 {
        width: 100%;
        font-size: 14px;
    }
    .landig-arrow {
        height: unset;
    }
    .landing-badge h3 {
        width: 80%;
    }
    .conference-landing {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .art-display {
        flex-direction: column;
    }
    .landing-badge {
        text-align: center;
    }
    .landing-badge h3 {
        width: 100%;
    }
    .badge-yellow {
        margin: auto;
    }
    .code-cart h1 {
        margin: auto !important;
    }
}
