/* **************FIRST SECTION*************** */
.first-section-pk {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0px 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.pk-first-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.first-section-pk-text {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.first-section-pk-text h1 {
  font-size: 40px;
  color: #ffffff;
}

.first-section-pk-text h2 {
  color: #ffffff;
  font-size: 31px;
  font-weight: 400;
  padding-top: 10px;
}

.first-section-pk-text p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  padding: 30px 0;
}

.first-section-pk-text a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  background-color: #90dae5;
  padding: 15px 35px;
  border-radius: 4px;
}

/* *****************SECOND SECTION***************** */
.second-section-pk {
  width: 100%;
  height: fit-content;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}

.pk-second-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-section-pk p {
  width: 280px;
  text-align: center;
  color: #601b3a;
  font-size: 20px;
  padding-bottom: 0px;
}

.second-section-pk p span {
  font-weight: bold;
  font-size: 40px;
  padding-top: 15px;
}

.promises {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px 0; */
}

.promise {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  padding: 20px 0;
  margin: 0 10px 0 0;
}

.promise p {
  text-align: left;
  padding-left: 10px;
  width: 200px;
  font-size: 18px;
  color: #601b3a;
  font-weight: bold;
}

.promise-img {
  width: 90px;
  height: 90px;
  background-color: #c4c4c4;
  border-radius: 100%;
  display: block;
}

/* **********Third section*************** */
.third-section-pk {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background-color: white;
}

.pk-third-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.third-section-pk-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 70px;
}

.third-section-pk-text h1 {
  font-size: 40px;
  color: #f2b21c;
}

.third-section-pk-text p {
  color: black;
  font-size: 18px;
  font-weight: 400;
  width: 500px;
  padding-top: 20px;
}

/* **************FOURTH SECTION************ */
.fourth-section-pk {
  width: 100%;
  /* padding: 40px 0; */
  /* background-color: #bb3f6e; */
  background-repeat: no-repeat;
  background-size: 50% 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fourth-section-pk-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-left: 100px; */
}

.pk-fourth-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 320px;
}

.pk-fourth-section img {
  width: 40%;
  height: 100%;
}

.fourth-section-pk-text {
  width: 50%;
}

.fourth-section-pk-text h1 {
  font-family: 'Maroon';
  color: #f3b21c;
  font-weight: 400;
  font-size: 30px;
}

.fourth-section-pk-text h2 {
  color: white;
  font-size: 30px;
  font-family: 'Clarendon-Bold';
  padding-bottom: 20px;
}

.fourth-section-pk-text h3 {
  font-family: 'Maroon';
  color: #ffffff;
  font-weight: 400;
  font-size: 31px;
}

.fourth-section-pk-text h4 {
  color: white;
  font-size: 41px;
  font-family: 'Clarendon-Bold';
}

.fourth-section-pk-text span {
  color: #f3b21c;
  font-family: 'Clarendon-Bold';
  font-weight: bold;
}

#sneak {
  padding-bottom: 20px;
}

/* *********fith secton********* */
.fifth-section-pk {
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  font-weight: 400;
  flex-direction: column;
}

.fifth-section-pk h1 {
  font-family: 'Maroon';
  font-size: 30px;
  color: #5c2945;
  font-weight: 400;
}

.fifth-section-pk h2 {
  font-size: 30px;
  color: #c64f6f;
  font-family: 'Clarendon-Bold';
  font-weight: bold;
}

.fifth-section-pk h3 {
  color: #c74e70;
  font-size: 18px;
  font-weight: 400;
  padding-top: 20px;
  font-family: 'Clarendon-Bold';
}

.promises-tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  align-items: center;
}

.tab {
  width: 160px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #f2b21c;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#tab1 {
  background-color: #ec6f2f21;
}

#tab2 {
  background-color: #7fc2b921;
}

#tab3 {
  background-color: #b2a93321;
}

#tab4 {
  background-color: #f3b21c21;
}

.tab img {
  height: 80%;
}

.dots {
  width: 130px;
  height: 0px;
  border: 1px dotted #7b3d5d;
  opacity: 0.5;
}

.tabsOpacity {
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}

.tab1 {
  width: 95%;
  margin: 30px auto;
  /* height: 400px; */
  background-color: #ec6f2f0d;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 2.5%;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.17);
}

.tab1-text,
.tab2-text {
  width: 405px;
}

.tab1-text h2 {
  color: #eb6f30;
  font-size: 20px;
  font-family: 'Clarendon-Bold';
  padding: 0;
  font-weight: bold;
}

.tab1 img {
  width: 50%;
  height: 350px;
  border-radius: 40px;
}

.tab1-text h1 {
  color: #7b3d5d;
  font-family: 'Clarendon-Bold';
  font-size: 25px;
  padding: 0;
  font-weight: bold;
}

.tab1-text p,
.tab2-text p {
  font-family: 'Uniform';
  color: black;
  font-weight: 400;
  padding-top: 20px;
  font-size: 14px;
}

.tab2-text h1 {
  color: #7fc2b9;
  font-family: 'Clarendon-Bold';
  font-size: 20px;
  font-weight: bold;
  padding: 0;
}

.tab2-text h2 {
  color: #c64f6f;
  padding: 0;
  padding: 0;
  font-weight: bold;
  font-size: 25px;
}

#tab3-h1 {
  color: #f3b21c;
  font-size: 20px;
}

.tab4-h1 {
  color: #b2a933 !important;
  font-size: 20px !important;
}

.desktop-animation {
  display: block;
}

.mobile-animation {
  display: none;
}

#tab3-h2 {
  color: #b2a933;
  font-size: 25px;
}

#tab4-h2 {
  color: #f3b21c;
  font-size: 25px;
}

#tab5-h1 {
  color: #7b3d5d;
  font-size: 20px;
}

#tab5-h2 {
  color: #7eb2ad;
  font-size: 25px;
}

/* **********SIXTH SECTION********** */

.sixth-section-pk {
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pk-sixth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sixth-section-pk h1 {
  font-family: 'Maroon';
  font-size: 30px;
  font-weight: 400;
  color: #5c2945;
}

.sixth-section-pk h2 {
  color: #c64f6f;
  font-family: 'Clarendon-Bold';
  font-size: 35px;
  /* width: 30px; */
}

.sixth-section-pk img {
  margin: 30px 0;
  width: 100%;
}

.sixth-section-pk p {
  width: 540px;
  font-size: 20px;
  font-weight: 400;
}

/* ********SEVENTH SECTION******* */
.seventh-section-pk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  margin: 10px 0 0px;
}

.pk-seventh-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pk-seventh-section-inverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.seventh-section-pk h1 {
  font-size: 30px;
  font-family: 'Maroon';
  font-weight: 400;
  color: #5c2945;
}

.seventh-section-pk h2 {
  font-size: 35px;
  color: #c64f6f;
  font-family: 'Clarendon-Bold';
  /* width: 300px; */
}

.seventh-section-pk p {
  color: black;
  font-size: 20px;
  padding-top: 20px;
  width: 450px;
}

.seventh-inverse {
  flex-direction: row-reverse;
}

.seventh-inverse p {
  width: 600px;
}

/* ********Eighth Section********* */
.eighth-section-pk {
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pk-eighth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.eighth-section-pk h1 {
  font-size: 30px;
  font-family: 'Maroon';
  font-weight: 400;
  color: #5c2945;
}

.eighth-section-pk h2 {
  font-family: 'Clarendon-Bold';
  font-size: 35px;
  color: #c64f6f;
  font-weight: 400;
}

.eighth-section-pk p {
  width: 100%;
  /* text-align: center; */
  margin-top: 50px;
  font-size: 20px;
  font-weight: 400;
}

.eighth-section-images {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.eighth-section-image {
  width: 390px;
  height: 390px;
  position: relative;
  padding: 0 10px;
}

.eighth-section-image img {
  width: 100%;
  height: 100%;
}

.eighth-image-text {
  width: 150px !important;
  height: 150px !important;
  position: absolute;
  left: -110px;
  top: 70px;
}

.eighth-image-text2 {
  width: 150px !important;
  height: 150px !important;
  position: absolute;
  right: -110px;
  top: 0px;
}

/* **************NINTH SECTION************* */
.ninth-section-pk {
  /* width: 100%; */
  height: fit-content;
  padding: 40px 35px;
  margin: 100px auto;
  background-color: #5c2945;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pk-ninth-section {
  height: fit-content;
}

.ninth-section-pk img {
  margin-top: -90px;
}

.ninth-section-pk h3 {
  color: #f2b21c;
  font-weight: 400;
  font-size: 18px;
}

.ninth-section-pk h2 {
  color: #ffffff;
  font-size: 40px;
}

.ninth-section-pk p {
  padding: 25px 0 40px 0;
  color: #ffffff;
  width: 70%;
  font-size: 20px;
}

.ninth-section-pk-text a {
  text-decoration: none;
  color: #ffffff;
  padding: 15px 35px;
  background-color: #f2b21c;
  border-radius: 4px;
  font-size: 20px;
}

/* .fifth-section-pk-desktop {
  display: block;
} */
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 25px !important;
}
/* .fifth-section-pk-mobile {
  display: none;
} */

.desktop-image-tab {
  display: block;
}

.mobile-image-tab {
  display: none;
}

.sixth-section-pk-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sixth-section-pk-text img {
  width: 50%;
}

.sixth-section-pk-text p {
  text-align: left;
}

.reusable-section-image {
  width: 50%;
  padding-right: 20px;
}

/* .pk-fifth-section-h2 {
  width: 60%;
} */

/* 







*/
@media screen and (max-width: 1400px) {
  .eighth-section-image {
    width: 320px;
    height: 320px;
    position: relative;
    /* padding: 0 10px; */
    margin: 0 10px;
  }

  .eighth-section-images {
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .first-section-pk-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    align-items: flex-start;
  }

  .promises {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }

  .promise {
    margin: 0 auto;
  }

  .third-section-pk img {
    padding: 0 20px;
  }

  .third-section-pk-text {
    padding: 0 20px;
  }

  .fourth-section-pk-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* padding-left: 100px; */
    padding: 0 20px;
  }

  .promises-tabs {
    width: 95%;
    margin: 0 auto;
  }

  .tab1 {
    width: 90%;
  }

  .fifth-section-pk h1,
  .fifth-section-pk h2 {
    padding-left: 30px;
  }

  .tab1-text h2 {
    font-size: 25px;
  }

  .tab1-text h1 {
    font-size: 40px;
  }

  .tab1-text p,
  .tab2-text p {
    font-size: 15px;
  }

  .tab {
    height: 50px;
  }

  .dots {
    width: 10%;
  }

  .tab2-text h1 {
    font-size: 40px;
  }

  #tab3-h1 {
    font-size: 40px;
  }

  #tab4-h2 {
    font-size: 40px;
  }

  #tab5-h1,
  #tab5-h2 {
    font-size: 30px;
  }

  .fifth-section-pk h2 {
    font-size: 30px;
  }

  .fifth-section-pk h3 {
    font-size: 15px;
    padding-left: 20px;
  }

  .sixth-section-pk img {
    width: 94%;
    /* padding: 0 20px; */
  }

  .seventh-section-pk-text {
    padding: 0 20px;
  }

  .seventh-section-pk img {
    width: 500px;
    padding: 0 20px;
  }

  .seventh-inverse img {
    width: 327px;
    padding-left: 20px;
  }

  .eighth-section-images {
    justify-content: center;
  }

  .eighth-section-image {
    width: fit-content;
    height: fit-content;
    position: relative;
    padding: 0 10px;
  }

  .eighth-section-image img {
    width: 250px;
  }

  .eighth-section-pk p {
    width: 50%;
    font-size: 18px;
  }

  img.eighth-image-text2 {
    width: 100px;
    right: -100px;
    width: 150px;
    height: 100px;
  }

  img.eighth-image-text {
    width: 100px;
    left: -80px;
    height: 100px;
  }

  .ninth-section-pk {
    width: 90%;
  }

  .ninth-section-pk p {
    width: unset;
  }

  .ninth-section-pk h2 {
    color: #ffffff;
    font-size: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .first-section-pk-text {
    width: 400px;
  }

  .first-section-pk img {
    width: 500px;
  }

  .third-section-pk-text {
    padding-right: 25px;
  }

  .third-section-pk-text h1 {
    width: 384px;
  }

  .third-section-pk-text p {
    width: 384px;
  }

  .third-section-pk img {
    width: 400px;
    padding-left: 25px;
  }

  .seventh-section-pk p {
    width: 397px;
  }

  .seventh-inverse img {
    width: 280px;
  }

  .seventh-inverse p {
    width: 473px;
  }
}

@media screen and (max-width: 998px) {
  .pk-fourth-section {
    height: fit-content;
  }

  .desktop-animation {
    display: none;
  }

  .mobile-animation {
    display: block;
  }

  .desktop-image-tab {
    display: none;
  }

  .mobile-image-tab {
    display: block;
  }

  /* .fifth-section-pk-mobile {
    display: block;
  } */

  /* .fifth-section-pk-desktop {
    display: none;
  } */

  .sixth-section-pk {
    width: 100%;
    padding: 40px 0 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .fifth-section-pk h1,
  .fifth-section-pk h2 {
    padding-left: 0px;
  }

  .pk-fourth-section img {
    width: 100%;
    padding-top: 00px;
  }

  #sneak {
    padding-bottom: 00px;
  }

  .promise {
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 140px;
  }

  .promise p {
    padding-bottom: 0;
    padding-left: 0;
    text-align: center;
    padding-top: 10px;
    width: 90%;
  }

  .first-section-pk-text a {
    padding: 20px 40px;
  }

  .first-section-pk-text {
    width: 50%;
    padding: 30px 20px;
  }

  .pk-third-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .first-section-pk img {
    width: 400px;
  }

  .third-section-pk {
    flex-direction: column-reverse;
  }

  .third-section-pk img {
    padding: 0;
  }

  .third-section-pk-text {
    padding: 0;
    text-align: center;
    width: 70%;
    align-items: center;
  }

  .third-section-pk-text h1 {
    width: 90%;
    padding-top: 30px;
    font-size: 31px;
  }

  .pk-fourth-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .fourth-section-pk-text {
    align-items: center;
  }

  .third-section-pk-text p {
    text-align: center;
    width: 90%;
  }

  .fifth-section-pk {
    text-align: center;
  }

  .fifth-section-pk h2 {
    font-size: 30px;
    padding: 0;
  }

  .tab1 {
    margin: 0 auto;
  }

  .tab1-text,
  .tab2-text {
    width: 100%;
    text-align: left;
  }

  .tab1-text h1 {
    font-size: 30px;
    padding: 0;
  }

  .tab2-text h1 {
    font-size: 30px;
    padding: 0;
  }

  #tab3-h1 {
    font-size: 30px;
    padding: 0;
  }

  #tab4-h2 {
    font-size: 20px;
    padding: 0;
  }

  .tab4-h1 {
    color: #b2a933 !important;
    font-size: 20px !important;
  }

  #tab5-h1,
  #tab5-h2 {
    font-size: 20px;
  }

  .tab1-text p,
  .tab2-text p {
    font-size: 12px;
  }

  .tab1 img {
    width: 50%;
  }

  .promises-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 20px !important;
    justify-content: unset;
  }

  .tab {
    flex: 0 0 auto;
    margin: 0 10px !important;
    width: 119px !important;
  }

  .sixth-section-pk img {
    width: 90%;
  }

  .sixth-section-pk h2 {
    font-size: 30px;
  }

  .sixth-section-pk p {
    font-size: 18px;
  }

  .seventh-section-pk img {
    width: 45%;
  }

  .seventh-inverse img {
    width: 30%;
  }

  .seventh-section-pk h2 {
    font-size: 30px;
  }

  .seventh-section-pk p {
    width: 397px;
    font-size: 18px;
  }

  .seventh-section-pk-text {
    width: 50%;
  }

  .eighth-section-images {
    flex-direction: column;
  }

  .eighth-section-pk p {
    width: 80%;
  }

  .ninth-section-pk h2 {
    color: #ffffff;
    font-size: 30px;
  }

  .ninth-section-pk p {
    font-size: 18px;
  }

  .ninth-section-pk p {
    padding: 20px 0 40px 0;
  }

  .ninth-section-pk-text a {
    padding: 20px 40px;
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .tab1-text p,
  .tab2-text p {
    font-size: 14px;
    margin: 0 auto;
    width: 100%;
  }

  .pk-first-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .eighth-section-pk h2 {
    font-family: 'Clarendon-Bold';
    font-size: 35px;
    color: #c64f6f;
    font-weight: 400;
    text-align: center;
  }

  .first-section-pk {
    flex-direction: column-reverse;
    align-items: center;
  }

  .first-section-pk-text {
    width: 90%;
    align-items: center;
    text-align: center;
  }

  .first-section-pk-text h1 {
    font-size: 31px;
  }

  .first-section-pk-text h2 {
    font-size: 20px;
  }

  .first-section-pk-text p {
    font-size: 16px;
  }

  .promises {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    width: unset;
  }

  .third-section-pk-text {
    width: 90%;
  }

  .fourth-section-pk {
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    background-position: center center;
  }

  .fourth-section-pk-text {
    width: 100%;
    padding: 0;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
  }

  .fourth-section-pk-text h2 {
    padding-bottom: 42px;
  }

  .tab1 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    height: 540px;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: -2px !important;
    left: 0;
    width: 100%;
  }

  .tab1 img {
    margin-top: 31px;
    width: 100%;
  }

  .tab2-text h1 {
    font-size: 30px !important;
  }

  .tab1-text p,
  .tab2-text p {
    font-size: 14px;
    /* margin: 0 auto; */
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .pk-seventh-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .pk-seventh-section-inverse {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .fifth-section-pk h2,
  .tab1-text h1,
  .tab2-text h1 {
    text-align: center;
  }

  .fifth-section-pk {
    text-align: center;
  }

  .dots {
    width: 50px !important;
    padding: 0px 42px;
  }

  .seventh-section-pk {
    flex-direction: column;
    text-align: center;
    /* margin-top: -30px; */
    width: 100%;
    padding: 40px 0;
  }

  .seventh-section-pk h2 {
    width: 90%;
    margin: 0 auto;
  }

  .seventh-section-pk img {
    width: 70%;
    margin-top: 40px;
  }

  .seventh-inverse img {
    padding: 0;
    margin-top: 30px;
    width: 50%;
  }

  .seventh-section-pk-text {
    width: 100%;
    padding-bottom: 25px;
    text-align: center;
  }

  .seventh-section-pk p {
    width: 95%;
    font-size: 18px;
    margin: 0 auto;
  }

  .eighth-section-pk p {
    font-size: 16px;
  }

  .ninth-section-pk {
    width: 80%;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .ninth-section-pk-text {
    margin-top: 75px;
  }

  .ninth-section-pk {
    margin: 60px auto;
  }
}

@media screen and (max-width: 600px) {
  .first-section-pk img {
    width: 100%;
  }

  .third-section-pk-text {
    width: 100%;
  }

  .third-section-pk img {
    width: 90%;
  }

  .third-section-pk-text h1 {
    text-align: center;
  }

  .third-section-pk-text p {
    width: 90%;
    text-align: center;
  }

  .tab2-text h1 {
    font-size: 25px !important;
  }

  .tab1-text h1 {
    font-size: 25px;
  }

  .sixth-section-pk p {
    width: 90%;
    font-size: 18px;
  }

  .seventh-section-pk p {
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
  }

  .seventh-section-pk img {
    width: 95%;
  }

  .eighth-section-image img {
    width: 235px;
  }

  img.eighth-image-text {
    width: 122px;
    left: -68px;
    width: 103px !important;
    height: 103px !important;
  }

  img.eighth-image-text2 {
    height: 103px;
    height: 103px !important;
    right: -48px;
    top: 15px;
    width: 103px !important;
  }

  .eighth-section-image {
    padding: 10px 10px;
  }

  .eighth-section-pk p {
    font-size: 15px;
    width: 90%;
  }

  .ninth-section-pk img {
    margin-top: -90px;
    width: 220px;
  }

  .ninth-section-pk {
    width: 75%;
  }

  .ninth-section-pk-text {
    margin-top: 41px;
    width: 100%;
  }
}

.cooper_promise_pg {
  color: white;
}

.project_5_usb {
  color: #ea6f30;
  text-transform: uppercase;
  font-weight: bold;
}
.project_2_live {
  color: #7fc2b9;
  text-transform: uppercase;
  font-weight: bold;
}
.project_2_laser {
  color: #f3b21b;
  text-transform: uppercase;
  font-weight: bold;
}
.project_2_kit_box {
  color: #b1a933;
  text-transform: uppercase;
  font-weight: bold;
}
.project_2_microphone {
  color: #7b3d5d;
  text-transform: uppercase;
  font-weight: bold;
}

.promise3_blod_text {
  color: #f3b21b;
  text-transform: uppercase;
  font-weight: bold;
}
.promise_5_blod_text {
  color: #55b9e3;
  text-transform: uppercase;
  font-weight: bold;
}

.recipes_bold_text {
  color: #7b3d5d;
  text-transform: uppercase;
  font-weight: bold;
}
.medallion_bold_text {
  color: #f3b21b;
  text-transform: uppercase;
  font-weight: bold;
}
