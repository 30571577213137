.quite-main-wrapper {
    max-width: 1200px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;


}

.quite-first-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    position: relative;

}


.quite-first-section h1 {
    font-weight: 400;
    font-size: 62px;
}


.quite-first-section h1>span {
    border-bottom: 1px solid black;
    padding-bottom: 40px;
}

.quite-firstsection-image1 {
    width: 690px;
    height: 355px;
    /* margin-top: 250px; */
    /* margin-right: -100px; */
    position: absolute;
    right: 450px;
    bottom: -50px;
}

.quite-firstsection-image2 {
    width: 490px;
    height: 560px;
    margin-left: -50px;
}

.quiet-second-section {
    width: 100%;
    padding: 100px 0 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.quiet-second-section-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
}

.quiet-second-section-card1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

.quiet-second-section-card p,
.quiet-second-section-card1 p {
    padding-top: 20px;
}

.quiet-third-section {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 40px 0;
}

.quiet-third-section p {
    width: 320px;
    padding: 0 20px;
}

.quiet-fifth-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
}

.quiet-fifth-section p {
    padding: 0 30px;
}

.quiet-sixth-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    text-align: center;
}

.quiet-sixth-section h2>span {
    border-bottom: 1px solid black;
    padding-bottom: 30px;
}

.quiet-sixth-section h2 {
    font-weight: 400;
    font-size: 48px;
}

.quiet-sixth-section p {
    padding-top: 50px;
}

.quiet-sixth-section h1 {
    padding-top: 30px;
    font-size: 62px;
    font-weight: 400;
}

.quite-first-section p {
    padding-top: 30px;
}

.f-paragraph {
    margin-top: 150px;
    font-size: 31px;
    padding: 20px 0;
    text-align: center;
}

.f-heading {
    text-align: center;
    margin: 30px 0;
}

.f-image {
    margin: 20px 0;
}

.f-paragraph2 {
    text-align: center;
}

.quite-Ffourth-image {
    width: 50%;
}

.fifth-f-image {
    width: 50%;
}

@media screen and (max-width:1200px) {
    .quite-firstsection-image2 {
        width: 440px;
        height: 500px;
        margin-left: -50px;
    }

    .quite-firstsection-image1 {
        width: 571px;
        height: 277px;
        /* margin-top: 250px; */
        /* margin-right: -100px; */
        position: absolute;
        right: 391px;
        bottom: -18px;
    }

    img.quite-fourth-image {
        width: 45%;
    }

}

@media screen and (max-width:998px) {
    .quite-first-section {
        flex-direction: column;
        align-items: center;
    }

    .quite-firstsection-image2 {
        width: 440px;
        height: 500px;
        margin: 50px auto;
    }

    .quite-firstsection-image1 {
        position: unset;
    }

    .quiet-second-section {
        flex-direction: column;
    }

    .quite-main-wrapper {
        text-align: center;
    }

    .quiet-third-section {
        flex-direction: column-reverse;
    }

    .quiet-third-section p {
        padding-top: 30px;
    }

    .quite-first-section p {
        padding-top: 50px;
    }

    .quiet-fifth-section {
        flex-direction: column;
    }

    .quiet-fifth-section p {
        padding-top: 30px;
    }

    .quiet-fifth-section-reverse {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width:600px) {
    .quite-first-section h1 {
        font-weight: 400;
        font-size: 31px;
    }

    .quite-first-section h1>span {
        border-bottom: 1px solid black;
        padding-bottom: 21px;
    }

    .quite-firstsection-image2 {
        width: 95%;
    }

    .quite-firstsection-image1 {
        width: 95%;
    }

    .quite-main-wrapper img {
        width: 100%;
        margin: 0 auto;
    }

    .quite-main-wrapper p {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
    }

    .quiet-second-section-card {

        width: 100%;
    }

    .quiet-second-section-card1 {

        width: 100%;
    }

    .quiet-sixth-section h2 {
        font-weight: 400;
        font-size: 31px;
    }

    .quiet-sixth-section h2>span {
        border: none;
    }

    .quiet-sixth-section h1 {
        font-size: 32px;
    }

    .quiet-second-section {
        padding: 40px 0;
    }
}