.as_newslatter {
  position: absolute;
  bottom: 5px;
  padding: 1rem;
}
.as_newslatter .as_p {
  color: var(--as_black_varient);
}
.as_newslatter_wrap {
  background-color: var(--as_white);
  width: 600px;
  padding: 1.5rem 1.2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: var(--as_box_shadow);
  align-items: center;
  justify-content: center;
  border-radius: var(--as_rounded);
}
.as_newslatter_wrap .as_h2 {
  color: var(--as_black_varient);
}
.as_newslatter_wrap .as_newslatter_form {
  width: 50%;
  /* height: 80px; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.as_newslatter_wrap .as_newslatter_form .as_input {
  width: 93%;
  border-radius: 0.3rem !important;
  font-size: 0.8rem;
  border: 1px solid #e3e3e3 !important;
  padding: 0.5rem;
}
.as_newslatter_wrap .as_newslatter_form .as_button {
  background: var(--as_secondary);
  color: var(--as_white);
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  padding: 0.5rem 0;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  margin-top: -8px;
}
.as_newslatter_wrap .as_newslatter_form .as_button:hover {
  background: var(--as_secondary_varient);
}
/* _______________________Media_Queries_______________________ */
@media screen and (max-width: 650px) {
  .as_newslatter_wrap {
    width: 100%;
    gap: 0.5rem;
  }
}
