.iframe-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframe-container iframe {
  padding: 40px 0;
  width: 720px;
  height: 380px;
}

@media screen and (max-width: 998px) {
  .iframe-container iframe {
    width: 560px;
  }
}
@media screen and (max-width: 600px) {
  .iframe-container iframe {
    width: 300px;
    height: 220px;
  }
}
