@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@200;300;400;500&display=swap');

:root {
  --as_black: #000;
  --as_light: #555;
  --as_black_varient: #000000a6;
  --as_white: #fdfdfd;
  --as_white-varient: #fdfdfd63;
  --as_yellow: #ffd205;
  --as_primary: #c74e70;
  --as_secondary: #5c2945;
  --as_secondary_varient: #5c2945f2;
  --as_gray: #f2faff;
  --as_box_shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --as_rounded: 0.3rem;
  --as_transition: 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.hoverable {
  transition: all 0.2s ease-in;
}
.as_homepage_header_slider_tab,
.as_homepage_header_slider_mobile {
  display: none;
}
@media screen and (max-width: 900px) {
  .as_homepage_header_slider_tab {
    display: block;
  }
  .as_homepage_header_slider_desktop {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .as_homepage_header_slider_tab {
    display: none;
  }
  .as_homepage_header_slider_mobile {
    display: block;
  }
}

/* .as_what_you_get_homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
} */

.hoverable:hover {
  transition: all 0.2s ease-in;
  transform: scale(1.05);
  cursor: pointer;
}

.header-font {
  width: 100%;
}

.first-section-hp-birthday {
  background-color: #f5ebe6;
}

@keyframes fade-in {
  from {
    max-height: 0px;
    -webkit-transition: max-height 1s ease;
    overflow: hidden;
  }

  to {
    max-height: 700px;
    -webkit-transition: max-height 1s ease;
  }
}

@keyframes fade-out {
  from {
    max-height: 250px;
    -webkit-transition: max-height 1s ease;
  }

  to {
    max-height: 0px;
    -webkit-transition: max-height 1s ease;
    overflow: hidden;
  }
}

.banner-container {
  overflow: hidden;
  position: relative;
  width: 400px; /* Adjust this width as per your requirement */
  height: 100%;
  -webkit-mask-image: linear-gradient(
    0deg,
    transparent,
    #fff 10%,
    #000 75%,
    transparent
  );
}

.image-wrapper {
  display: grid;
  column-gap: 15px;
  row-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  animation: moveBanner 10s linear infinite;
  animation-fill-mode: both; /* Keep the first and last frames of the animation visible */
}
.image-wrapper img {
  border-radius: 15px;
}
.banner-image {
  width: 100%;
}
.__pf [style*='--s-lg'] > .pf-c {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.slider-margin {
  margin-top: -40px;
  height: 100%;
}
@keyframes moveBanner {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@media only screen and (max-width: 700px) {
  .banner-container {
    height: 300px;
  }
}

.transition {
  animation: fade-in 2.2s;
}

.transition-fade-out {
  animation: fade-out 2.2s;
}

.newoffer {
  position: absolute;
  width: 300px;
  left: 40%;
  text-align: center;
  top: 100px;
}

.offere-banner {
  background-color: #90dae5;
  text-align: center;
  color: white;
  padding: 10px 10px;
}
.offere-banner a {
  color: #5c2945;
}
.homepage-toys {
  width: 100%;
}

.perfectday {
  background-color: #5c2945;
  color: white;
  border-radius: 8px;
  padding: 20px 0px;
}

.fsbgimg-desktop {
  padding-top: 50px;
}

.codebox {
  background-color: #5c2945;
  border-radius: 8px;
  color: white;
  margin-top: 5px;
  padding: 20px 0px;
}

#percent {
  font-size: 50px;
  font-weight: 900;
}

#off {
  font-size: 20px;
}

#p25 {
  font-size: 86px !important;
  color: white;
}

.codebox h2 {
  margin-top: 0;
}

.spans {
  display: flex;
  justify-content: center;
}

.homepage-ninth-section img {
  padding-left: 100px;
}

.percentage {
  display: flex;
  flex-direction: column;
}

.codebox h3 {
  margin-bottom: 0;
  color: red;
}

.homeschoolingBg-banner {
  background-image: url('../Assets/homeschoolingForm2/web-long.png') !important;
  height: 400px !important;
}

.homeschoolingBg-banner,
.homeschoolingBg-bannerMobile {
  width: 100% !important;

  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.homeschoolingBg-bannerMobile {
  background-image: url('../Assets/homeschoolingForm2/home-bck.png') !important;
  height: 680px !important;
  /* max-height: 1000px !important; */
  position: relative;

  /* height: 2000px !important; */
}

.adventure-position-mobile,
.adventure-position-tablet {
  display: none;
}

.adventure-position {
  width: 100%;
}

.adventre-width img {
  display: block;
}

.first-section-hp {
  width: 100%;
  height: 520px;

  background-size: 62% !important;
  object-fit: cover;
  background-repeat: no-repeat;
  /* background-position: bottom center !important; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-color: #f5ebe6 !important;
  z-index: 111111;
  background-position: right;
}

@media (min-width: 1450px) {
  .first-section-hp {
    /* background-position: 53% !important; */
    background-size: 52% !important;
    /* background-size: cover !important; */
  }
}

.first-section-birthday .main-wrapper {
  padding-top: 50px !important;
}

.first-section-birthday img {
  object-fit: scale-down;
}

.catapult2 {
  position: relative;
}

.catapult2 img {
  height: 160px;
  /* display: none; */
  margin-top: 20px;
}

.catapult {
  display: flex;
  text-decoration: none;
  width: 500px;
  padding: 10px 20px;
  background-color: #5c2945;
  position: absolute;
  bottom: -85px;
}

.catapult h2,
.catapult h4 {
  color: white;
}

.catapult img {
  position: absolute;
  top: -85px;
  right: 0;
}

.catapult p:nth-child(1) {
  color: #c5e67d !important;
  padding: 5px 0px !important;
}

.catapult p:nth-last-child(1) {
  color: #f2b21c !important;
  padding: 5px 0px !important;
}

.first-section-hp-text-home h1 {
  padding-top: 50px;
  width: 75%;
  margin: auto;
  color: black;
}

.first-section-hp-text-home p {
  padding: 20px;
  width: 70%;
  margin: auto;
  color: black;
}

.first-section-hp-text-home {
  width: 780px;
  text-align: center;
  margin: auto;
  height: -moz-fit-content;
  height: fit-content;
  /* padding-left: 30px; */
  /* padding-top: 100px; */
  /* padding-left: 20%; */
  z-index: 1111111;
}

.first-section-hp-text {
  /* width: 400px; */
  /* height: fit-content; */
  /* padding-bottom: 130px; */
  /* padding-left: 30px; */
  /* padding-top: 100px; */
  /* padding-left: 20%; */
  width: 40%;
  z-index: 1111111;
}

.fifth-section-hp-text-mobile {
  margin-top: 470px;
}

.first-section-hp-text h1 {
  color: white;
  font-family: 'Uniform';
  font-size: 40px;
  font-weight: bold;
}

.first-section-hp-text p {
  font-family: 'Uniform';
  color: #71141c;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  padding: 20px 0;
  /* text-shadow: black -2px 0 4px; */
}

.fsbgimg-mobile {
  display: none;
}

.first-section-hp-buttons-home {
  justify-content: center;
  width: 103%;
  display: flex;
  flex-direction: row;
}

.first-section-hp-buttons-home a {
  width: 30%;
  margin: 0px 5px;
  justify-content: center;
  text-decoration: none;
  border-radius: 5px;
}

.homepage-first-section-home {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.slider-div {
  position: relative;
  height: 100%;
  width: 80%;

  display: flex;
  justify-content: flex-end;
}
.box-absolute {
  height: 450px;
  position: absolute;
  left: 0;
  bottom: 50px;
  z-index: 9999;
}
.first-section-hp-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.first-section-hp-buttons a {
  text-decoration: none;
  font-family: 'Uniform';
  font-weight: 600;
  font-size: 17px;
  padding: 10px 15px;
  border-radius: 4px;
  margin-right: 5px;
  margin: 0;
}

.first-section-hp-buttons-birthday a:nth-child(1) {
  background-color: #f8473d !important;
  color: white !important;
}

.first-section-hp-buttons-birthday a:nth-child(2) {
  border: 1px solid #f8473d !important;
  color: #f8473d !important;
  background: unset !important;
}

.first-section-birthday a:nth-child(1) {
  background-color: #327cd6 !important;
  color: white !important;
}

.first-section-birthday-img {
  width: 100%;
}

.first-section-birthday a:nth-child(2) {
  border: 1px solid #327cd6 !important;
  color: #327cd6 !important;
  background: unset !important;
}

.first-section-hp-txt-birthday h1 {
  color: #222222 !important;
}

.first-section-hp-txt-birthday p {
  color: #222222 !important;
}

.first-section-birthday .first-section-hp-text {
  padding-bottom: 50px;
}

.first-section-birthday .first-section-hp-buttons {
  justify-content: center;
}

.first-section-birthday p {
  text-align: center;
}

#claimOffer {
  background-color: #ea3546;
  color: white;
}

#claimOffer-homeschooling {
  background-color: #ea3546;
  color: white;
  display: flex;
  align-items: center;

  padding: 13px 15px;
}

.gift-arrow-icon {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  background: #000000;
}

.gift-arrow-icon p {
  color: #601b3a;
  font-size: 16px;
  padding: 0px !important;
}

.gift-arrow-icon svg {
  font-size: 20px;
}

#giveGift {
  border: 1px solid #ea3546;
  color: #ea3546;
  background-color: white;
  margin-left: 5px;
}

.first-section-hp-img {
  width: 55%;
  align-items: flex-end;
  /* margin-bottom: -60px; */
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  /* bottom: -5%; */
}

.first-section-hp-img img {
  height: 100%;
  /* object-fit: cover; */
  /* margin-top: 120px; */
  width: 100%;
  right: 0;
  bottom: -5%;
  position: absolute;
}

.first-section-hp-homeschooling {
  background-color: #f6f7f8 !important;
}

.homeschoolingImage {
  margin-bottom: -70px;
  /* width: 50%; */
}

.homeSchollingTitle {
  color: #1e6fa6 !important;
}

.homeschoolBtn1 {
  background-color: #90dae5 !important;
  color: white !important;
}

.homeschoolBtn2 {
  border: 1px solid #90dae5 !important;
  color: #90dae5 !important;
}

@media screen and (max-width: 425px) {
  .first-section-hp-mobile {
    height: 650px !important;
  }
}

.bonus-popup {
  width: 450px;
  height: 120px;
  background-color: #5c2945;
  position: absolute;
  bottom: -80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* left: -30px; */
}

.bonus-text {
  width: 50%;
}

.bonus-text h2 {
  color: #c5e67d;
  font-size: 12px;
  font-family: 'Uniform';
}

.bonus-text h1 {
  font-size: 20px;
  font-family: 'Uniform';
  color: white;
  padding: 5px 0;
}

.bonus-text a {
  width: fit-content;
  color: #f2b21c;
  text-decoration: none;
  font-size: 12px;
  font-family: 'Uniform';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.bonus-text svg {
  margin: 0 5px;
}

.bonus-popup img {
  margin-top: -50px;
}

.second-section-hp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.homepage-second-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.homepage-second-section h1 {
  color: #601b3a;
  padding-bottom: 10px;
}

.homepage-second-section iframe {
  padding-top: 20px;
  width: 720px;
  height: 380px;
}

.homepage-second-section-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}
.homepage-second-section-kit-button-link {
  padding: 12px 25px;
  font-size: 18px;
}

.second-section-hp-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 50px;
  padding-bottom: 20px;
}

#wellrounded-header {
  color: #aed653;
}

#unique-header {
  color: #d7694a;
}

#unplug-header {
  color: #f2b21c;
}

.second-section-hp-img,
.second-section-hp-img-inverse {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.second-section-hp h1 {
  font-size: 31px;
  font-family: 'Uniform';
  font-weight: 600;
}

.second-section-hp h2 {
  font-size: 20px;
  color: #5c6b78;
  font-family: 'Uniform';
  font-weight: 400;
}

.second-section-hp-child {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.second-section-hp-child-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  height: 200px;
  margin-top: 20px;
}

.second-section-hp-child-text h1 {
  font-size: 40px;
  font-weight: 600;
}

.second-section-hp-child-text p {
  color: #5c6c7c;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  font-weight: 400;
}

.third-section-hp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--as_gray);
  padding: 2rem 0;
}

.homepage-third-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.homepage-third-section a {
  padding: 15px 35px;
  font-size: 20px;
  text-decoration: none;
  border-radius: 7px;
  background-color: var(--as_primary);
  color: white;
}

.third-section-hp h1 {
  color: var(--as_secondary);
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 25px;
  font-weight: 800;
}

/* .third-section-hp img {
  margin-top: 10px;
} */

.progress-hp {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.progress-hp-desc {
  background: var(--as_white-varient);
  width: 30%;
  border-radius: var(--as_rounded);
  transition: all var(--as_transition);
}
.progress-hp-desc:hover {
  box-shadow: var(--as_box_shadow);
  border-radius: var(--as_rounded);
  transition: all var(--as_transition);
}

.progress-hp-desc h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.progress-hp-desc a {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 0px;
  text-decoration: none;
  background-color: unset;
}

.progress-hp-desc img {
  width: min-content;
  height: 122px;
}

.progress-hp-desc-school img {
  height: 180px !important;
}

.progress-hp-desc p {
  font-family: 'Poppins', sans-serif;
  line-height: 22px;
  color: var(--as_light);
  font-size: 15px;
  font-weight: 400;
}

.fourth-section-hp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.homepage-fourth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fourth-section-hp h1 {
  font-size: 40px;
  color: #601b3a;
  text-align: center;
  width: 100%;
  padding-top: 100px;
}

.fourth-section-hp p {
  color: #5c6b78;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 50px;
}

.homepage-fourth-section img {
  width: 80%;
}

.big-box-label {
  width: 50% !important;
  margin-top: 30px;
}

.fifth-section-hp {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  /* background-color: var(--as_gray); */
}

.homepage-fifth-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homepage-fifth-section img {
  /* height: 300px; */
  height: 450px;
}

.fifth-section-hp-text {
  width: 50%;
}

.fifth-section-hp-text h1 {
  color: var(--as_secondary);
  font-weight: 800;
  font-size: 40px;
  font-family: 'Montserrat', sans-serif;
}

.fifth-section-hp-text p {
  color: var(--as_light);
  margin: 20px 0;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}

.third-section-hp-mobile {
  display: none;
}

.subtitle-customiztion {
  font-size: 28px !important;
  padding-top: 20px;
  text-align: center;
}

.sixth-section-hp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  gap: 1rem;
}

.homepage-sixth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.sixth-section-hp h1 {
  font-weight: 800;
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: var(--as_secondary);
}

.sixth-section-hp p {
  width: 90%;
  font-family: 'Poppins', sans-serif;
  line-height: 22px;
  color: var(--as_light);
  font-size: 15px;
  font-weight: 400;
}

.sixth-section-hp img {
  /* max-width: 800px; */
  width: 100%;
  object-fit: cover;
  /* margin: 40px 0; */
}

.sixth-section-hp a {
  padding: 15px 35px;
  font-size: 20px;
  text-decoration: none;
  border-radius: 7px;
  background-color: var(--as_primary);
  color: var(--as_white);
}

.medallion-cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.medallion-card {
  width: 270px;
  height: 350px;
  background-size: 100% 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px;
}

.medallion-card p {
  color: #000000;
  font-size: 25px;
  font-weight: bold;
  padding-top: 20px;
}

.medallion-card-1 {
  background-image: url(../Assets/Homepage/spy.png);
}

.medallion-card-2 {
  background-image: url(../Assets//Homepage/stone.png);
}

.medallion-card-3 {
  background-image: url(../Assets//Homepage/quite.png);
}

.medallion-card-4 {
  background-image: url(../Assets//Homepage/cruise.png);
}
.seventh-section-hp {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  gap: 1rem;
}

.testimonials-slider {
  display: block;
}

.seventh-section-hp h1 {
  width: 50%;
  font-weight: 800;
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: var(--as_secondary);
  margin: 0 auto;
}

.first-section-image {
  width: 100%;
}

.seventh-section-slider-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.section7-slider-img,
.section7-text-card {
  width: 50%;
  height: 300px;
  /* border: 3px solid #f2b21c; */
  border-radius: 8px;
}

.section7-slider-img {
  overflow: hidden;
  box-shadow: 0px 0px 0px 10px rgba(247, 204, 43, 1) inset !important;

  background-size: cover;
  background-repeat: no-repeat;
}

.section7-slider-img img {
  width: 100%;
  height: 100%;
}

.section7-text-card {
  display: flex;
  flex-direction: column;
  align-items: left;
  /* padding: 20px; */
  border: 1px solid #f2b21c;
  margin: 0px 10px;
  border-radius: 8px;
  justify-content: center;
}

.section7-text-card p {
  color: #5c6c7c;
  text-align: left;
  padding: 20px 20px 20px 20px;
  font-size: 20px;
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  padding-left: 20px;
  /* padding-top: 40px; */
  margin-bottom: 20px;
}

.profile-picture {
  width: 45px;
  height: 45px;
  background-color: #c4c4c4;
  border-radius: 100px;
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* padding-left: 15px; */
}

.name h3 {
  color: #c74e70;
  font-weight: 400;
  font-size: 18px;
}

.name p {
  padding: 0;
  color: var(--as_light);
  font-weight: 400;
  font-size: 14px;
}

.slick-next,
.before-next {
  right: 40%;
}

.before-prev {
  left: 40%;
}
.slick-next,
.before-next,
.before-prev {
  bottom: -50px;
  background-color: var(--as_primary) !important;
  margin-top: 0 !important;
  position: absolute;
  cursor: pointer;
}

/* .slick-next::before,
.slick-prev::before {
  content: ">";
} */

/* .before-next ::before {
  position: absolute;
  right: 32%;
  top: 300px;
  cursor: pointer;
}

.before-prev::before {
  position: absolute;
  left: 32%;
  top: 300px;
  cursor: pointer;
} */

.testimonial-star {
  width: 30%;
  padding-left: 20px;
}

.testimonial-star {
  width: 100%;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: -45px;
  z-index: -1;
  gap: 0.5rem;
}
.slick-dots li {
  margin: 0;
}

.slick-dots li button::before {
  color: transparent;
  width: 10px;
  height: 10px;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
  background-color: rgba(199, 78, 112, 0.3) !important;
  border-radius: 50px !important;
}

.slick-dots li button::after {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
  background-color: rgba(199, 78, 112, 0.3) !important;
  border-radius: 50px !important;
}

.slick-dots li button::before {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  /* padding: 5px; */
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
  background-color: rgba(199, 78, 112, 1) !important;
  border-radius: 50px !important;
}

.slick-dots li button:active {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  /* padding: 5px; */
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
  background-color: rgba(199, 78, 112, 0.3) !important;
  border-radius: 50px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: transparent;
  width: 10px;
  height: 10px;
}

.eighth-section-hp {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  padding: 2rem 0;
  background-color: var(--as_gray);
  /* background: rgb(199, 78, 112); */
  /* background: linear-gradient(
    270deg,
    rgba(199, 78, 112, 0.1) 100%,
    rgba(199, 78, 112, 0) 73%
  ); */
}

.homepage-eighth-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
.homepage-eighth-section img {
  width: 20%;
  object-fit: cover;
}

.eighth-section-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.eighth-section-text h1 {
  /* color: #b2593f;
  font-size: 31px;
  font-weight: 600; */
  font-weight: 800;
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  color: var(--as_secondary);
}

.eighth-section-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--as_light);
}

.ninth-section-hp {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f2b21c12;
  position: relative;
  height: 400px;

  background: rgb(242, 178, 28);
  background: linear-gradient(
    90deg,
    rgba(242, 178, 28, 0.08167016806722693) 0%,
    rgba(242, 178, 28, 0) 80%
  );
}

.homepage-ninth-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ninth-section-hp-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}
.ninth-section-hp-text h1 {
  font-weight: 800;
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  color: var(--as_secondary);
}

.ninth-section-hp-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: var(--as_light);
}

.bold-paragraph {
  font-weight: bold !important;
  /* padding: 15px 0 5px !important; */
}

.ninth-section-hp-text a {
  background-color: var(--as_primary);
  padding: 13px 30px;
  text-decoration: none;
  color: var(--as_white);
  font-weight: 400;
  border-radius: 7px;
  font-size: 16px;
}

.tenth-section-hp {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 3rem 0 2rem 0;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
}

.tenth-section-hp h1 {
  font-weight: 700;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  color: var(--as_secondary);
}

.FAQs {
  /* max-width: 1200px; */
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.faq {
  width: 97.2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--as_light);
  padding: 0.6rem 0.5rem;
  color: var(--as_white);
  cursor: pointer;
  border-radius: var(--as_rounded);
}

.faq p {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.faq svg {
  cursor: pointer;
}

.faq-right,
.faq-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

/* .faq-right {
  align-items: flex-start;
} */

/* .faq-left {
  align-items: flex-end;
} */

.faq-content {
  background-color: var(--as_gray);
  color: var(--as_light);
  box-shadow: var(--as_box_shadow);
}

.faq-content p {
  padding: 0.6rem 0.5rem;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}
.elevent-section-hp {
  width: 100%;
  background-color: var(--as_gray);
  padding: 2rem 0;
}

.homepage-eleventh-section {
  width: 90%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.elevent-section-hp img {
  height: 80px;
}

.shadow {
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
}

.profile-picture img {
  width: 100%;
  height: 100%;
}

.homepage-first-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.first-section-hp-mobile {
  display: none;
}

.big-box-mobile {
  display: none;
}

.first-section-hp-mobile {
  display: none;
}

.main-404-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
}

.main-404-page h1 {
  font-size: 40px;
  font-weight: 700;
  color: #333333;
  width: fit-content;
  text-align: center;
}

.main-404-page a {
  text-decoration: none;
  color: white;
  padding: 12px 42px;
  background-color: #5c2945;
  border-radius: 5px;
  margin-top: 30px;
}

.kit-button-link {
  padding: 20px 70px;
  color: var(--as_white);
  text-decoration: none;
  background-color: var(--as_primary);
  border-radius: 6px;
  font-size: 20px;
}

.emotional {
  text-align: center;
  padding: 60px 0px 40px 0px;
}

.emotional-display {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emotional-h1 {
  color: var(--as_secondary);
  width: 80%;
  font-weight: 700;
  font-size: 40px;
  text-align: left;
}

.emotional-display div:nth-child(1) {
  width: 480px;
}

.emotional-display div:nth-child(2) {
  width: 400px;
}

.emotional-display img {
  width: 100%;
}

.emotional-p {
  color: #5c6c7c;
  padding: 20px 0px 50px 0px;
  width: 70%;

  text-align: left;
}

.emotional-flex {
  display: grid;
  column-gap: 20px;

  grid-template-columns: repeat(4, 1fr);
}

.emotional-flex div {
  display: flex;
  flex-direction: column;
}

.emotional-flex div {
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 20px 0px;
}

.emotional-flex p,
.emotional-flex h1 {
  padding: 15px 5px;
  margin: 5px;
  font-size: 12px;
  border-radius: 6px;
}

.emotional-first {
  background: linear-gradient(
      180deg,
      rgba(227, 140, 91, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f9fbff;
  border: 1px solid #dce8ff;
  border-radius: 10px;
  transition: 0.3s;
}

.emotional-first h1 {
  color: #c65638;
  font-size: 20px;
  border-bottom: 1px solid #dce8ff;
  width: 60%;
  margin: auto;
}

.emotional-first:hover {
  box-shadow: 0px 4px 25px rgba(198, 86, 56, 0.15);
}

.emotional-third:hover {
  box-shadow: 0px 4px 25px rgba(174, 214, 83, 0.15);
}

.emotional-second:hover {
  box-shadow: 0px 4px 25px rgba(123, 61, 93, 0.15);
}

.emotional-fourth:hover {
  box-shadow: 0px 4px 25px rgba(109, 128, 142, 0.15);
}

.emotional-second h1 {
  color: #7b3d5d;
  font-size: 20px;
  border-bottom: 1px solid #dce8ff;
  width: 60%;
  margin: auto;
}

.emotional-second p,
.emotional-first p,
.emotional-third p,
.emotional-fourth p {
  color: black;
  border-bottom: 1px solid #dce8ff;
  font-weight: 500;
  font-size: 17px;
  width: 60%;
  margin: auto;
}

.emotional-second p:nth-last-child(1),
.emotional-first p:nth-last-child(1),
.emotional-third p:nth-last-child(1),
.emotional-fourth p:nth-last-child(1) {
  border-bottom: unset;
}

.emotional-second {
  background: linear-gradient(
      180deg,
      rgba(123, 61, 93, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f9fbff;
  border-radius: 10px;
  border: 1px solid #dce8ff;
  transition: 0.3s;
}

.emotional-third h1 {
  color: #aed653;
  font-size: 20px;
  border-bottom: 1px solid #dce8ff;
  width: 60%;
  margin: auto;
}

.emotional-third {
  background: linear-gradient(
      180deg,
      rgba(174, 214, 83, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f9fbff;
  border: 1px solid #dce8ff;
  border-radius: 10px;
  transition: 0.3s;
}

.emotional-fourth h1 {
  color: #6d808e;
  font-size: 20px;
  border-bottom: 1px solid #dce8ff;
  width: 60%;
  margin: auto;
}

.emotional-fourth {
  background: linear-gradient(
      180deg,
      rgba(109, 128, 142, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f9fbff;
  border: 1px solid #dce8ff;
  border-radius: 10px;
  transition: 0.3s;
}

.adventure-cloud {
  background-image: url('../Assets/Homepage/advbck.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.adventure-cloud h2 {
  font-size: 38px;
}

.adventure-cloud p {
  padding: 20px 0px;
  width: 70%;
  padding-bottom: 40px;
}

.adventure-cloud a {
  background: #182f43;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  padding: 17px 60px;
  color: white;
  text-decoration: none;
}

.advsmall-box {
  width: 50%;
}

.advsmall-box img {
  width: 100%;
}

.adventure-banner {
  background-image: url('../Assets/Homepage/adventure.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.adventure-banner .main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;

  padding: 100px 0px;
}

.adventurebox p {
  padding-bottom: 15px;
}

.adventure-none {
  opacity: 0;
}

.adv-manymore {
  font-size: 34px;
  color: #c64f6f;
  text-align: center;
  text-decoration: none;
}

.adventure-banner .main-wrapper p {
  padding: 20px 0px;
  line-height: 35px;
  width: 55%;
  margin: auto;
}

.adventure-banner .main-wrapper h1 {
  font-size: 48px;
  width: 60%;
}

.adventure-banner .main-wrapper a {
  color: white !important;
  text-decoration: none;
  padding: 12px 25px;
  background: #90dae5;
  border-radius: 4px;
  /* border: 1px solid #90dae5; */
  cursor: pointer;
  transition: 0.4s;
}

.adventurebox {
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 40px;
  padding: 100px 0px;
  /* display: flex;
    flex-direction: column;
    position: relative; */
}

.bck-adv img {
  width: 100%;
}

.adv-mob {
  display: none;
}

.adv-absolute {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 32%;
  justify-content: center;
  padding-left: 600px;
  text-align: left;
}

.adventurebox p:nth-child(2) {
  font-family: 'MaroonedOnMars BB';
  color: white;
  font-weight: 600;
  padding-top: 10px;
}

.adventurebox h1 {
  width: 100%;
  color: #c64f6f;
  padding: 10px 0px;
  text-align: center;
  font-size: 40px;
}

.adventurebox-padding {
  margin-top: -220px;
}

.adv-toggle {
  border: 1px solid #90dae5;
  padding: 12px 0px;
  background-color: #90dae5;
  text-align: center;
  width: 200px;
  margin: auto;
  margin-bottom: 150px;
  margin-top: 50px;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 4px;

  color: white;
}

.adv-toggle:hover {
  border: 1px solid #90dae5;
  background-color: white;
  color: #90dae5;
}

.cub-bck {
  background-image: url('../Assets/Homepage/aabck.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.cub-bck .main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0px;
}

.adv-box1 {
  width: 30%;
}

.adv-box1 p:nth-child(3) {
  padding: 20px 0px 30px 0px;
}

.adv-box1 p:nth-child(1) {
  color: #f2b21c;
}

.adv-box1 a {
  background-color: #f2b21c;
  text-decoration: none;
  color: white;
  padding: 12px 25px;
  border-radius: 4px;
}

/* 















*/

@media screen and (min-width: 1980px) {
  .section7-slider-img,
  .section7-text-card {
    height: 345px;
    /* border: 3px solid #f2b21c; */
    border-radius: 8px;
  }

  .before-next {
    right: 32%;
    top: 390px;
    position: absolute;
    cursor: pointer;
  }

  .before-prev {
    left: 32%;
    top: 390px;
    margin: 0;
    position: absolute;
    cursor: pointer;
  }
}

@media screen and (min-width: 1921px) {
  .fsbgimg-1920,
  .fsbgimg-1400,
  .fsbgimg-998,
  .fsbgimg-600 {
    display: none;
  }

  /* .first-section-hp {
    height: 700px;
  } */
}

@media screen and (max-width: 1920px) {
  .fsbgimg-1921,
  .fsbgimg-1400,
  .fsbgimg-998,
  .fsbgimg-600 {
    display: none;
  }
}

@media screen and (max-width: 1900px) {
  .section7-text-card p {
    padding: 20px 20px 10px 20px;
  }

  .name p {
    padding: 0;
  }

  .fsbgimg-600,
  .fsbgimg-900 {
    display: none;
  }

  .homeschoolingBg-bannerMobile {
    height: 680px !important;
  }
}

@media screen and (max-width: 1690px) {
  .section7-text-card p {
    font-size: 18px;
    padding: 20px 20px 30px 20px;
  }

  .name p {
    padding: 0;
  }

  .fsbgimg-600,
  .fsbgimg-900 {
    display: none;
  }
}

@media screen and (max-width: 1600px) {
  .first-section-hp-img {
    width: 60%;
  }

  .fsbgimg-600,
  .fsbgimg-900 {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .fsbgimg-1921,
  .fsbgimg-1920 {
    display: none;
  }

  .fsbgimg-1400 {
    display: block;
    position: absolute;
    width: 65%;
    /* bottom: -75px; */
  }

  .first-section-hp-img img {
    height: 90%;
    right: 0;
    bottom: -5%;
    position: absolute;
  }

  .fsbgimg-600,
  .fsbgimg-900 {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .homepage-second-section {
    margin-top: 50px;
  }

  .bonus-popup {
    bottom: -125px;
  }

  .fsbgimg-600,
  .fsbgimg-900 {
    display: none;
  }
}

/* #hs-h1 {
  color: #1e6fa6;
} */
@media screen and (max-width: 1200px) {
  .first-section-hp-img img {
    height: 90%;
    right: 0;
    /* bottom: -13.5%; */
    position: absolute;
  }

  .second-section-hp-child-text {
    width: 90%;
  }

  .first-section-hp {
    background-position: center right;
  }

  .homepage-first-section {
    padding: 0 20px;
  }

  .second-section-hp-img-inverse {
    padding-right: 20px;
  }

  .eighth-section-text {
    padding: 0 20px;
  }

  .fifth-section-hp img {
    padding-right: 20px;
  }

  .main-wrapper.homepage-eighth-section img,
  .fifth-section-hp-text,
  .ninth-section-hp-text,
  .footer2 p,
  .logo-subscribe {
    padding-left: 20px;
  }

  .homepage-ninth-section img {
    width: 50%;
    padding-right: 20px;
  }

  .tenth-section-hp {
    width: 96%;
  }

  .elevent-section-hp img {
    width: 140px;
    height: 140px;
  }

  .fsbgimg-600,
  .fsbgimg-900 {
    display: none;
  }

  .homeschoolingBg-bannerMobile {
    height: 520px !important;
  }

  .first-section-hp-text-home {
    width: 100%;
  }

  .adventure-wrapper {
    width: 90% !important;
    margin: auto;
    /* padding-left: 20px !important;
        padding-right: 20px !important; */
  }

  .cub-bck .main-wrapper {
    width: 90% !important;
    margin: auto;
  }

  .adventure-banner {
    background-position: center;
  }
}
/* as_media  */

@media screen and (max-width: 1024px) {
  .before-prev {
    left: 39%;
  }
  .ninth-section-hp {
    height: auto;
    padding-top: 20px;
  }
  .tenth-section-hp {
    padding: 2rem 0;
  }
  .tenth-section-hp {
    width: 100%;
  }
  .elevent-section-hp img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}

@media screen and (max-width: 998px) {
  .first-section-hp-desktop {
    display: none;
  }
  .homepage-second-section iframe {
    width: 560px;
  }
  .adventure-position-desktop {
    display: none;
  }

  .adventure-position-tablet {
    display: block;
    width: 100%;
  }

  .homepage-fourth-section img {
    width: 100%;
  }

  .FAQs {
    width: 100%;
  }

  .homepage-ninth-section img {
    padding-left: 0px;
  }

  .adventure-position {
    width: 100%;
  }

  .homepage-toys {
    display: none;
  }

  .homeschoolingBg-bannerMobile {
    height: 720px !important;
  }

  .first-section-hp-birthday {
    background-color: #f5ebe6 !important;
  }

  .first-section-hp {
    background-image: unset !important;
  }

  .catapult2 {
    height: unset;
  }

  .catapult2 img {
    height: unset;
  }

  .first-section-image {
    width: 100%;
    /* margin: 0px 0 60px 0; */

    padding-bottom: 50px;
  }

  .fsbgimg-998 {
    display: block;
  }

  .fsbgimg-mobile {
    display: block;
  }

  .advsmall-box {
    width: 80%;
  }

  .advsmall-box img {
    width: 100%;
  }

  .fsbgimg-998 {
    display: block;
  }

  .big-box-mobile {
    display: block;
  }

  .big-box-desktop {
    display: none;
  }

  .big-box-label {
    width: 80% !important;
  }

  .second-section-hp-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .first-section-hp-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: fit-content !important;
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .fsbgimg-desktop {
    padding-top: 0px;
  }

  .homepage-fourth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 140px;
  }

  .second-section-hp-img {
    padding-left: 0px;
    justify-content: center;
  }

  .second-section-hp-img-inverse {
    padding-right: 0px;
  }

  .second-section-hp-child-text {
    padding-right: 0px;
  }

  .eighth-section-text {
    padding: 0px;
  }

  .fifth-section-hp img {
    padding-right: 0px;
  }

  .main-wrapper.homepage-eighth-section img,
  .fifth-section-hp-text,
  .ninth-section-hp-text,
  .footer2 p,
  .logo-subscribe {
    padding-left: 0px;
  }

  .second-section-hp-img-inverse {
    justify-content: center;
    flex-direction: row-reverse;
  }

  .homepage-ninth-section img {
    width: 50%;
    padding-right: 0px;
  }

  /* .first-section-hp {
    flex-direction: column;
    justify-content: flex-end;
    height: 59vh;
    padding: 80px 0px;
  } */
  .section7-slider-img {
    margin-left: 10px;
    /* border: 3px solid red; */
  }

  .first-section-hp-img img {
    position: unset;
    /* width: 100%; */
    height: 50%;
  }

  .first-section-hp-img {
    width: 100%;
    margin: 0 auto;
    height: fit-content;
    bottom: -30px;
  }

  .first-section-hp-text {
    padding: 0px;
    width: 60%;
    text-align: center;
    padding-top: 50px;
    margin-top: -40px;
  }

  .fifth-section-hp-text-mobile {
    margin-top: 470px !important;
    padding-bottom: 50px !important;
  }

  .first-section-hp-text h1 {
    font-size: 31px;
    padding: 0 20px;
  }

  .first-section-hp-text p {
    padding: 20px 18px 0 18px;
  }

  .first-section-hp-buttons {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .bonus-popup {
    bottom: -157px;
    left: 0;
  }

  .bonus-text {
    align-items: flex-start;
    text-align: left;
  }

  .bonus-text h1 {
    font-size: 16px;
    padding: 5px 0;
  }

  .first-section-hp-buttons {
    justify-content: center;
    width: 100%;
  }

  .first-section-hp-buttons a {
    font-size: 16px;
  }

  .second-section-hp {
    margin-top: 20px;
  }

  .second-section-hp h1 {
    text-align: center;
    padding: 0 10px;
    /* padding-top: 50px; */
  }

  .second-section-hp h2 {
    text-align: center;
    padding: 0 10px;
    margin-top: 20px;
  }

  .second-section-hp-child {
    flex-direction: column;
    width: 100%;
  }

  .second-section-hp-child-text {
    width: 300px;
    margin-top: 20px;
    align-items: center;
    text-align: left;
    height: unset;
  }

  .second-section-hp-child-text h1 {
    width: 100%;
    padding: 0 20px;
    text-align: center;
    font-size: 25px;
  }

  .second-section-hp-child-text p {
    text-align: center;
    padding: 0 20px;
  }

  .third-section-hp-desktop {
    display: none;
  }

  .third-section-hp-mobile {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .third-section-hp-mobile img {
    /* width: 90%; */
    display: none;
  }

  .progress-hp-desc img {
    width: min-content;
    margin-top: 0px;
    height: unset;
  }

  .third-section-p-cards {
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .progress-hp {
    display: block;
    width: 100%;
  }

  .progress-hp-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 520px;
    height: 400px;
    margin: 30px auto;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.05);
  }
  .fifth-section-hp-text p {
    width: 90%;
  }

  .whatyouget-slider {
    margin: 0px 0px;
  }

  .progress-hp-desc h1 {
    text-align: center;
    padding: 0 20px;
    font-size: 20px;
  }

  .progress-hp-desc img {
    display: block;
    /* width: 150px;
    height: 100px; */
    margin: 0 auto;
  }

  .progress-hp-desc p {
    width: auto;
    text-align: center;
    margin: 0;
    padding: 15px 15px;
  }

  .fourth-section-hp {
    margin: -60px 0 0 0;
  }

  .fourth-section-hp h1 {
    width: unset;
    font-size: 31px;
    padding: 40px 10px 0px 10px;
  }

  .fourth-section-hp p {
    text-align: center;
    padding: 0 20px;
  }

  .fourth-section-hp img {
    width: 100%;
  }

  .fifth-section-hp {
    flex-direction: column;
  }

  .fifth-section-hp-text {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .fifth-section-hp-text h1 {
    font-size: 31px;
    width: unset;
  }

  .fifth-section-hp-text p {
    margin: 0;
  }
  .slick-next,
  .before-next {
    right: 35%;
  }
  .before-prev {
    left: 34%;
  }

  .fifth-section-hp img {
    width: 40%;
  }

  .sixth-section-hp h1 {
    font-size: 31px;
    /* color: #601b3a; */
    font-weight: 800;
    width: 90%;
    text-align: center;
  }

  .sixth-section-hp p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    width: 90%;
    text-align: center;
  }

  .medallion-card p {
    font-size: 18px;
  }

  .medallion-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }

  .medallion-card {
    margin: 0 auto;
    width: 100%;
    background-size: contain;
    height: 350px;
    transition: all 0.2s ease-in-out;
  }

  .seventh-section-hp h1 {
    width: 90%;
    font-size: 31px;
  }

  /* .eighth-section-hp {
    padding: 30px 0;
  } */

  .eighth-section-text {
    width: 50% !important;
    margin: 30px 0 0 0;
    padding-right: 0 !important;
    text-align: center;
  }

  .eighth-section-hp img {
    width: 200px;
  }

  /* .ninth-section-hp {
    flex-direction: column-reverse;
    margin-bottom: 30px;
    margin-top: 0;
  } */

  /* .ninth-section-hp-text {
    padding-top: 30px;
    width: 90%;
    text-align: center;
  } */
  .homepage-ninth-section {
    padding: 0 1rem;
  }
  .ninth-section-hp img {
    transform: rotate(7.1deg);
  }

  /* .tenth-section-hp h1 {
    margin-bottom: 30px;
    font-size: 31px;
  } */

  .FAQs {
    flex-direction: column;
    align-items: center;
  }

  .faq-right,
  .faq-left {
    width: 90%;
  }

  .faq,
  .faq-content {
    width: 100%;
  }

  .elevent-section-hp img {
    width: 90px;
    height: 90px;
  }

  .footer1 {
    flex-direction: column;
    align-items: center;
  }

  /* .footer-links {
    padding: 20px 0;
    align-items: center;
  } */

  /* .newsletter-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  } */

  /* input.email-footer {
    margin-top: 9px;
  } */

  .logo-subscribe p {
    width: 400px;
  }

  /* .logo-subscribe {
    align-items: center;
    width: 100%;
  } */

  .footer2 {
    flex-direction: column;
  }

  .footer2 p {
    padding: 0;
    text-align: center;
  }

  /* .terms-privacy {
    padding: 0;
    text-align: center;
    padding-top: 15px;
  } */

  .terms-privacy a {
    color: #1e2833;
    text-decoration: none;
    padding: 0 30px;
    padding: 0 10px;
  }

  .faq p {
    font-size: 15px;
  }

  /* .logo-subscribe h6 {
    text-align: center;
  } */

  .homepage-first-section {
    flex-direction: column-reverse;
    padding: 0;
    height: 100%;
    justify-content: flex-end;
  }

  /* .homepage-fifth-section {
    flex-direction: column;
  } */

  /* .homepage-eighth-section {
    flex-direction: column;
  } */

  /* .homepage-ninth-section {
    flex-direction: column-reverse;
    margin-top: 100px;
  } */

  /* .ninth-section-hp {
    height: fit-content;
    padding: 50px 0px;
  } */

  /* .homepage-eleventh-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  } */

  .elevent-section-hp img {
    width: 75px;
    height: 75px;
    object-fit: cover;
  }

  .fsbgimg-600,
  .fsbgimg-1400 {
    display: none;
  }

  .emotional-flex div {
    width: 100%;
  }

  .emotional-h1 {
    width: 70%;
    margin: auto;
    text-align: center;
  }

  .emotional-p {
    width: 60%;
    text-align: center;
    margin: auto;
  }

  .emotional-display {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .emotional-display div:nth-child(1) {
    width: 100%;
  }

  .emotional-flex {
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    margin: auto;
  }

  .adventure-banner .main-wrapper h1 {
    width: 100%;
  }

  .catapult2 {
    left: 0;
    width: 100%;

    bottom: -55px;
  }

  .homepage-fifth-section img {
    height: unset;
  }
}

@media screen and (max-width: 600px) {
  .first-section-image {
    width: 100%;
    margin-bottom: -5px;
    /* margin: 0px auto 60px auto; */
    padding-bottom: 80px;
  }

  .homepage-second-section iframe {
    width: 300px;
    height: 220px;
  }

  .catapult2 {
    left: 0;
    bottom: -65px;
  }

  .catapult2 img {
    width: 100%;
  }

  .first-section-hp-text p {
    text-align: center;
  }

  .first-section-hp-mobile {
    height: fit-content !important;
    padding-bottom: 0;
  }

  .homepage-toys {
    display: none;
  }

  .fifth-section-hp-text-mobile {
    padding-bottom: 90px !important;
  }

  .main-404-page img {
    width: 95%;
  }

  .main-404-page h1 {
    width: 95%;
    margin: 0 auto;
  }

  .fsbgimg-998,
  .fsbgimg-1400 {
    display: none;
  }

  .fsbgimg-600 {
    display: block;
  }

  .big-box-label {
    width: 95% !important;
  }

  .sixth-section-hp img {
    max-width: 1200px;
    width: 100%;
    height: 100px;
    margin: 40px 0;
  }

  .second-section-hp-child {
    flex-direction: column;
    width: 100%;
  }

  .second-section-hp-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .progress-hp-desc {
    width: 255px;
  }

  .second-section-hp-child-text p {
    text-align: center;
    padding: 0px;
    width: 300px;
  }

  .second-section-hp-child-text h1 {
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: 25px;
  }

  .second-section-hp-child-text {
    width: fit-content;
    margin-top: 20px;
    align-items: center;
    text-align: center;
  }

  .faq,
  .faq-content {
    width: 420px;
  }

  /* .first-section-hp {
    flex-direction: column;
    justify-content: flex-end;
    height: 450px;
    padding: 80px 0px;
    width: 100%;
  } */
  .first-section-hp-text {
    padding: 0px;
    width: 100%;
    text-align: center;
    padding-top: 50px;
    margin-top: -40px;
  }

  .bonus-popup {
    width: 100%;
    bottom: -79px;
  }

  .section7-slider-img {
    margin: 0;
    width: 90%;
  }

  .section7-text-card {
    margin: 0px 0 0 0;
    width: 90%;
  }

  .seventh-section-slider-card {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .before-next {
    right: 13%;
    top: 600px;
  }

  .before-prev {
    left: 13%;
    top: 600px;
  }

  .eighth-section-hp {
    flex-direction: column !important;
  }

  .eighth-section-text {
    margin-top: 15px !important;
    width: 100% !important;
    padding: 0 !important;
    text-align: center;
    gap: 0.5rem;
    align-items: center;
  }

  .eighth-section-text p,
  .eighth-section-text h1 {
    width: 90%;
    margin-bottom: 20px;
  }

  .ninth-section-hp img {
    width: 90%;
  }

  .faq-right,
  .faq-left {
    width: 95%;
  }

  .faq,
  .faq-content {
    width: 90%;
  }

  /* .elevent-section-hp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;
    row-gap: 15px;
  } */
  /* .homepage-eleventh-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    display: grid;
  } */
  .elevent-section-hp img {
    width: 85%;
    height: unset;
    margin: 0 auto;
  }

  .medallion-cards {
    column-gap: 15px;
    row-gap: 15px;
  }

  .medallion-card {
    margin: 0 auto;
    width: 100%;
    background-size: contain;
    height: 275px;
  }

  .logo-subscribe p {
    text-align: center;
    padding-top: 20px;
    width: 90%;
  }

  .first-section-hp-text-home h1 {
    font-size: 28px !important;
  }

  .first-section-hp-text-home h1,
  .first-section-hp-text-home p {
    width: 90%;
  }

  .first-section-hp-buttons-home a {
    width: 45%;
    font-size: 14px;
    padding: 10px 0px !important;
  }

  .adventure-position {
    width: 100%;
  }

  .homeschoolingBg-bannerMobile {
    height: 700px !important;
  }

  .adventure-position-desktop,
  .adventure-position-tablet {
    display: none;
  }

  .adventure-position-mobile {
    display: block;
    width: 100%;
  }

  .emotional-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .emotional-flex div {
    width: 90%;
  }

  .emotional-third,
  .emotional-fourth {
    margin-top: 20px;
  }

  .emotional-h1 {
    width: 100%;
    font-size: 28px;
  }

  .emotional-p {
    width: 90%;
  }

  .title-customiztion {
    font-size: 26px !important;
  }

  .emotional-display div:nth-child(2) {
    width: 320px;
  }

  .emotional-display img {
    width: 100%;
  }

  .cub-bck .main-wrapper {
    flex-direction: column;
  }

  .adv-box1 {
    width: 100%;
    padding-bottom: 50px;
  }

  .adventurebox {
    grid-template-columns: repeat(1, 1fr);
  }

  .adventurebox-padding {
    margin-top: 0px;
  }

  .adventurebox {
    padding: 100px 0px;
  }

  .adventure-banner .main-wrapper p {
    line-height: 25px;
    width: 95%;
  }

  .adventure-banner {
    background-image: url('../Assets/Homepage/adventure1.png');
  }

  .adventure-none {
    display: none;
  }

  .adv-mob {
    display: block;
  }

  .adv-desk {
    display: none;
  }

  .header-font {
    width: 90%;
    padding-top: 15px;
  }

  .adventure-cloud {
    flex-direction: column;
  }

  .adventure-cloud div {
    width: 100%;
    text-align: center;
  }

  .adventure-cloud p {
    margin: auto;
    width: 90%;
  }
}

@media screen and (max-width: 425px) {
  /* .first-section-hp-mobile {
    height: 600px !important;
  } */
}

@media screen and (max-width: 375px) {
  .first-section-hp-buttons a {
    font-size: 12px;
  }

  .medallion-card {
    height: 250px;
  }

  .progress-hp-desc {
    width: fit-content;
  }

  .homeschoolingBg-bannerMobile {
    height: 620px !important;
  }
}

@media screen and (max-width: 320px) {
  .medallion-card {
    height: 211px;
  }

  .progress-hp-desc {
    width: fit-content;
  }
}

.bonus-box-banner {
  background-color: #fafc3e;
  height: 70px;
}

.bb-box-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 70px;
}

.yellow-link {
  text-decoration: none;
}

.bonus-box-banner img {
  height: auto;
  margin-top: -110px;
  z-index: 111111;
}

.bonus-box-banner h3 {
  font-size: 24px;
}

@media (max-width: 999px) {
  .bonus-box-banner {
    height: 130px;
    padding: 0px 10px;
  }

  .bb-box-div {
    height: 130px;
  }

  .bonus-box-banner h3 {
    font-size: 16px;
    margin-left: 10px;
  }

  .bonus-box-banner img {
    height: 110px;
    margin-top: 0;
    z-index: 111111;
  }
}

.water_slide_offer_warp {
  position: relative;
}
.water_slide_offer_constainer {
  position: absolute;
  bottom: -20px;
  background-color: #601b3a;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  padding: 0 7px 0 20px;
  /* #622330 */
}

.water_slide_content {
  color: #fff;
}
.water_slide_content h3 {
  font-size: 17px;
}
.water_slide_content p {
  font-size: 13px;
  margin: 5px 0;
}
.water_slide_btn {
  display: flex;
  align-items: center;
  color: #f6b525;
  margin-top: 10px;
}
.water_slide_btn a {
  font-size: 13px;
  text-decoration: none;
  color: #f6b525;
}
.water_slide_wrap {
  height: 100%;
  width: 45%;
}
.water_slide_wrap img {
  height: 115%;
  width: 100%;
  object-fit: cover;
  translate: 0px -10px;
}
.home_page_banner_img_wrapper {
  width: 60%;
}
.home_page_banner_img_wrapper img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
@media screen and (max-width: 825px) {
  .water_slide_offer_constainer {
    bottom: -50px;
    z-index: 10000000;
  }
}
@media screen and (max-width: 380px) {
  .water_slide_offer_constainer {
    width: 260px;
  }
}
