.thankyou {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}

.thankyou h2 {
  font-size: 36px;
  font-family: 'Varela Round', sans-serif;
  padding: 40px 0;
  font-family: 'Varela Round', sans-serif;
  font-weight: 500;
}

.thankyou p {
  font-size: 24px;
  font-family: 'Varela Round', sans-serif;
  font-weight: 400;
}

.thankyou span {
  font-size: 28px;
  font-weight: 700;
  padding-top: 20px;
}
