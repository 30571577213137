@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@200;300;400;500&display=swap');

/* .sixth-section-hp p {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #555 !important;
  margin-top: 20px !important;
  text-align: center !important;
  font-family: 'Poppins', sans-serif !important;
} */
@media screen and (max-width: 768px) {
  .homepage-eighth-section {
    flex-direction: column-reverse;
  }
  .homepage-fifth-section,
  .homepage-ninth-section {
    flex-direction: column;
  }
}
@media screen and (max-width: 425px) {
  .homepage-ninth-section img {
    width: 106%;
  }
  .fifth-section-hp-text p {
    padding: 0 1rem;
  }
  .eighth-section-hp {
    margin-top: 30px !important;
  }
  .homepage-eighth-section {
    gap: 0rem;
  }
  .fifth-section-hp img {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  /* homepage 'section spacing' */
  .progress-hp-desc h1 {
    margin-bottom: 0;
  }
  .fifth-section-hp img,
  .sixth-section-hp img {
    margin: 0;
  }
  .as_what_you_get_homepage,
  .ninth-section-hp-text {
    padding-top: 0;
  }
  .ninth-section-hp {
    padding: 0;
  }
  /* .FAQs {
    margin-bottom: 30px;
  } */
  .logo-subscribe {
    padding-top: 30px !important;
  }

  .as_what_you_get_homepage,
  .seventh-section-hp,
  .testimonials-slider {
    margin-top: 30px;
  }
  .fifth-section-hp {
    margin-top: 45px;
  }
  .sixth-section-hp {
    margin-top: 0;
  }
  .section7-text-card {
    height: fit-content;
    padding-bottom: 20px;
  }
  .section7-text-card p {
    padding: 20px;
  }
  .before-next,
  .before-prev {
    top: auto;
    bottom: -50px;
  }
  .slick-dots {
    bottom: -45px;
  }

  /* homepage 'left align text for mobile screen' */
  .second-section-hp-child-text h1,
  .second-section-hp-child-text p,
  .progress-hp-desc p,
  .as_what_text,
  .as_what_subtitle,
  .sixth-section-hp p {
    text-align: left !important;
  }
  /* .third-section-hp,
  .progress-hp-desc {
    height: fit-content;
  } */
  .progress-hp-desc a {
    padding: 20px 0px !important;
  }
  .progress-hp-desc h1 {
    margin-top: 0 !important;
  }
  .progress-hp-desc p {
    padding: 10px 15px 0 15px !important;
  }
  .as_what_subtitle {
    font-size: 1.3rem !important;
  }
  .as_h1.as_what_you_title_mobile {
    font-size: 2rem !important;
    margin-bottom: 1rem;
  }
  .as_what_you_get_slider {
    padding-top: 20px !important;
  }
  .section7-slider-img {
    background-position: center !important;
    background-size: cover !important;
  }

  .eighth-section-hp img {
    width: 125px !important;
  }
  .homepage-eleventh-section {
    grid-template-columns: repeat(3, 1fr) !important
    ;
  }
  .elevent-section-hp img {
    width: 65px !important;
  }
  .tenth-section-hp {
    padding: 0 !important;
  }
  .footer1 {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .logo-subscribe,
  .footer-links,
  .footer2 {
    width: 100% !important;
  }
  .terms-privacy {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px !important;
    gap: 0.5rem;
    margin: 0 auto;
  }
  .terms-privacy a {
    padding: 0 !important;
  }
}
@media screen and (max-width: 440px) {
  .ninth-section-hp-text h1,
  .eighth-section-text h1,
  .seventh-section-hp h1,
  .sixth-section-hp h1,
  .fifth-section-hp-text h1,
  .as_h1.as_what_you_title_mobile,
  .third-section-hp h1,
  .second-section-hp h1,
  .tenth-section-hp h1 {
    font-size: 22px !important;
    color: var(--as_secondary);
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
  }
  .ninth-section-hp-text {
    padding-top: 12px;
  }
  .second-section-hp h2,
  .eighth-section-text p,
  .ninth-section-hp-text p {
    font-size: 16px;
  }
  .tenth-section-hp h1 {
    margin-bottom: 10px;
  }
  .footer1 {
    align-items: flex-start;
    justify-items: center;
  }
  .logo-subscribe {
    grid-column: 1 / span 2;
  }
  .logo-subscribe img {
    width: 70% !important;
  }
  .footer-links {
    width: 80% !important;
    gap: 10px;
    align-items: flex-start !important;
    padding: 5px !important;
  }
  /* .third-section-hp,
  .progress-hp-desc {
    height: 400px;
  } */
  .as_what_you_get_homepage {
    margin-top: 60px;
  }
  /* .footer-links:nth-last-child(1) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
  } */
  .logo-subscribe h6,
  .newsletter-footer,
  .footer-links h1 {
    font-size: 14px !important;
    padding-top: 10px !important;
  }
  .footer-links h1 {
    padding-bottom: 0;
  }
  .logo-subscribe p {
    font-size: 13px !important;
    padding-top: 10px !important;
  }
  .footer-links a {
    font-size: 13px !important;
    padding: 0 !important;
  }
  .newsletter-footer {
    width: 90%;
  }
  .submit-footer {
    height: 30px !important;
    width: 30px !important;
  }
  .email-footer {
    font-size: 13px !important;
    margin-top: 0;
  }
  .terms-privacy a {
    width: 47%;
    font-size: 13px !important;
  }
  .social {
    gap: 10px;
  }
  .social div {
    width: 30px;
    height: 25px;
  }
  .footer2 {
    padding-top: 20px !important;
  }
  .as_what_title_text_wrap {
    margin-bottom: 0;
  }
  .kit-button-link,
  .sixth-section-hp a,
  .ninth-section-hp-text a {
    padding: 12px 25px;
    font-size: 18px;
  }
  .terms-privacy {
    width: 90%;
    display: flex;
    align-items: center;
  }
  .second-section-hp-grid {
    justify-items: center;
  }
  .second-section-hp-child-text,
  .second-section-hp-child-text p {
    width: 100%;
  }
  .second-section-hp-child {
    width: 90%;
  }
}
