.terms-div {
  max-width: 1100px;
  padding: 0 30px 100px;
  margin: 0 auto;
}

.terms-div h1 {
  padding: 50px 0 25px;
  color: #5c2945;
}

.terms-div h2 {
  padding: 25px 0;
  color: #5c2945;

}

.terms-div p {
  font-size: 16px;
}

.terms-div h3 {
  margin: 20px 0;
  color: #90dae5;
  font-size: 16px;
}

.terms-div ul {
  padding-left: 30px;
}

.terms-div ul>li {
  padding: 5px 0;
}

.terms-div p>a {
  font-weight: bold;
}

.terms-div img {
  margin-top: 20px;
}