.popbox-main {
    background-color: white;
}

.popbox-main-wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.popbox-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.popbox-cards-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.popbox-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    width: 320px;
    text-align: center;
}

.popbox-card h1 {
    font-size: 20px;
    width: 100%;
    margin: 20px 0;
}

.popbox-card h2 {
    font-size: 18px;
    font-weight: 400;
    color: #555555;
    width: 100%;
    margin: 20px 0x;
}

.popbox-card h3 {
    color: #ff000099;
    font-size: 14px;
    font-weight: 400;
    margin: 20px 0;
}

.popbox-card h5 {
    color: rgb(188, 51, 37);
    font-size: 20px;
    margin: -15px 0 20px 0;
}

.popbox-card a {
    margin: 20px 0;
    padding: 12px 15px;
    border: 1px solid rgb(26, 188, 156);
    text-decoration: none;
    color: black;
}

.popbox-card a:hover {
    background-color: #1abc9c;
    color: white;
}

.popbox-card p {
    color: #555555;
    font-size: 14px;
}

.popbox-card-costum {
    background-image: linear-gradient(rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)) !important;
}

.popbox-question {
    text-align: center;
    margin: 50px 0;
    color: #781a48;
}

.popbox-answer {
    text-align: center;
}

.popbox-giftcard {
    width: 100%;
    padding: 40px 0;
    background-image: repeating-linear-gradient(135deg, rgb(240, 243, 243), rgb(240, 243, 243) 3px, transparent 3px, transparent 6px) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}

.popbox-giftcard img {
    width: 400px;
}

.popbox-giftcard-text {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}

.popbox-giftcard-text a {
    color: #1abc9c;
    text-decoration: none;
    margin: 10px auto;
    font-weight: bold;
}

@media screen and (max-width:1100px) {

    .popbox-cards-main {
        flex-direction: column;
    }
}

@media screen and (max-width:768px) {
    .popbox-giftcard {
        flex-direction: column;
    }

    .popbox-answer {
        width: 90%;
        margin: 0 auto;
    }

    .popbox-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        width: 310px;
        text-align: center;
        /* margin: 0 auto; */
    }

}