.footer-main {
  width: 100%;
  background-color: var(--as_white);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--as_gray);
  border-top: 1px solid #55555536;
  /* padding: 0 2rem; */
}

.footer1 {
  width: calc(100% - 2rem);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 2rem 0;
  align-items: start;
  justify-items: center;
  gap: 1.2rem;
}

.logo-subscribe {
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.5rem;
}

.logo-subscribe img {
  width: 40%;
  object-fit: cover;
}

.logo-subscribe p {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--as_light);
}

.logo-subscribe h6 {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: var(--as_secondary);
}

.email-footer {
  width: 80%;
  font-size: 0.9rem;
  padding: 0 0.5rem;
  border: none;
  outline: none;
  background: transparent;
  border-bottom: 1px solid rgba(92, 107, 120, 0.5);
}

.thankyou-message {
  color: #1e2833;
  font-weight: 400;
  size: 18px;
  padding-top: 20px;
  bottom: -30px;
}

.email-footer::placeholder {
  color: rgba(92, 107, 120, 0.5);
}

.submit-footer {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  color: var(--as_white);
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--as_secondary);
  border: 1px solid var(--as_secondary);
  outline: none;
  cursor: pointer;
}

.newsletter-footer {
  display: flex;
}

.footer-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}

.footer-links h1 {
  font-weight: 800;
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  color: var(--as_secondary);
}

.footer-links a {
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: var(--as_light);
  text-decoration: none;
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
  text-align: left;
}

.footer-popup-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11111111;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-popup {
  width: 700px;
  height: 300px;
  background-color: white;
  outline: 15px solid rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  position: relative;
}

.popup-form {
  width: 700px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.popup-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-heading p {
  font-size: 15px;
  color: #5d5d5d;
}

.popup-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-form form#inf_form_8798ebd237af8b6c070d3ff7f0bb50c8 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.popup-form img.simage {
  height: 60px;
}

.popup-form .infusion-field {
  width: 100%;
  margin: 15px 0;
}

.popup-form .infusion-field span {
  height: 20px;
}

.popup-form input#inf_field_FirstName {
  width: 250px;
  background-color: #e9edf0;
  border: none;
  height: 25px;
  text-align: center;
  color: #9da9b3;
  /* outline: none; */
}

.name-footer {
  width: 100%;
  height: 35px;
  border: none;
  background-color: #e9edf0;
  text-align: center;
  outline: none;
  color: #9da9b3;
}

.footer-radio-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.footer-radio-btns p {
  color: #5d5d5d;
  padding: 0;
  width: fit-content;
}

.radio-btns-options {
  margin: 0 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popup-terms {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popup-terms label {
  margin: 0 10px;
  padding: 0;
  color: #5d5d5d;
  font-size: 15px;
}

.popup-terms a {
  color: #1193bb;
}

.radio-btns-options label {
  padding: 0 5px;
  font-size: 13px;
  color: #5d5d5d;
}

.footer-popup button {
  padding: 5px 15px;
  background-color: #8c384b;
  border: none;
  color: white;
  font-family: 'Legend';
  font-size: 20px;
  font-weight: bold;
  outline: none;
}

.footer-popup-close-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}

.social div {
  width: 20px;
}

.social a {
  padding-left: 20px;
  cursor: pointer;
}

.footer2 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  padding: 0 2rem;
  border-top: 1px solid #55555536;
}

.footer2 p {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--as_light);
  /* letter-spacing: 1px; */
}

.terms-privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.terms-privacy a {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--as_light);
  text-decoration: none;
}

.main-footer-thankyou {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-footer-thankyou h2 {
  margin-top: 30px;
  color: #5c2945;
}

.infusion-option label {
  margin: 0 5px;
}

.infusion-checkbox label {
  margin: 0 5px;
}

.first-name-footer {
  display: flex;
  flex-direction: column;
}

.first-name-footer input {
  margin-top: 5px;
}

.infusion-option-footer {
  margin: 0 5px;
}

@media screen and (max-width: 998px) {
  .footer1 {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: flex-start !important;
  }

  /* .footer-links {
    text-align: center;
    padding-left: 0px;
    width: 100%;
    align-items: center;
    padding-top: 20px;
  } */

  .footer2 {
    height: fit-content;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .footer2 p {
    padding-right: 00px;
  }

  /* .terms-privacy {
    padding: 0;
    text-align: center;
    padding: 33px 0;
  } */
}

@media screen and (max-width: 790px) {
  .footer-popup {
    width: 85%;
    height: 350px;
    justify-content: space-between;
  }

  .name-footer {
    width: 100%;
  }

  .popup-heading {
    width: 90%;
  }

  .popup-form {
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }

  .footer-radio-btns {
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
  }

  .footer-radio-btns p > span {
    display: none;
  }
}
