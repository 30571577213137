.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.as_what_you_get_slider {
  width: 100%;
  margin: 0 auto;
}
.as_what_you_get_slider .swiper-button-next::after,
.as_what_you_get_slider .swiper-button-prev::after {
  color: var(--as_primary);
}
.as_what_you_get_slider .swiper-button-next,
.as_what_you_get_slider .swiper-button-prev {
  width: 40px;
}

/* ======================================================= */
/* .as_what_you_get_heading {
  font-weight: 800;
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: var(--as_secondary);
} */
.as_what_you_get_container {
  display: flex;
  padding: 0 15%;
  overflow-y: hidden;
  align-items: center;
}
.as_what_you_get_container .as_left {
  width: 40%;
  padding-right: 40px;
}
.as_what_you_title {
  margin-bottom: 25px;
  font-weight: 600;
}
.as_what_title_text_wrap {
  margin-bottom: 15px;
}
.as_what_subtitle {
  color: #601b3a;
  margin-bottom: 0.5rem;
}
.as_what_you_get_container .as_right {
  width: 60%;
}
/* .as_what_img_row {
  padding-top: 6rem;
} */
.as_what_img_warp {
  width: 100%;
}
.as_what_you_get_mobile_img {
  display: none;
}
.as_what_you_get_slider {
  display: none;
}
.as_what_to_get_call_to_action {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .as_what_you_get_slider {
    display: block;
    width: 100%;
    padding-top: 3rem;
  }
  .as_what_you_title_mobile {
    text-align: center;
  }
  .as_what_title_text_wrap {
    width: 85%;
  }
  .as_what_you_get_container {
    padding: 0;
  }
  .as_what_you_get_container .as_left,
  .as_what_you_get_container .as_right {
    display: none;
  }
  .as_what_you_get_mobile_img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-top: 1rem;
    justify-content: center;
  }
  /* .as_what_you_get_img_wrap {
    min-width: 255px !important;
    height: 227px;
  } */
  .as_what_you_get_mobile_img_item {
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* margin: 1.5rem; */
  }
  .swiper-slide[data-swiper-slide-index='0'] .as_what_you_get_img_wrap {
    width: 200px !important;
  }
  .swiper-slide[data-swiper-slide-index='2'] .as_what_you_get_img_wrap {
    width: 250px;
  }
  .swiper-slide[data-swiper-slide-index='3'] .as_what_you_get_img_wrap {
    width: 240px;
  }
  .swiper-slide[data-swiper-slide-index='4'] .as_what_you_get_img_wrap {
    width: 240px;
  }
  .swiper-slide[data-swiper-slide-index='5'] .as_what_you_get_img_wrap {
    width: 235px;
  }
  .swiper-slide[data-swiper-slide-index='6'] .as_what_you_get_img_wrap {
    width: 210px;
  }
  .swiper-slide[data-swiper-slide-index='7'] .as_what_you_get_img_wrap {
    width: 200px;
  }
}
