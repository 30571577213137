.live-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
}

.event {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;
    width: 100%;

}

.event-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 300px;
}

.event-2 a {
    background-color: rgb(26, 188, 156);
    color: white;
    font-weight: bold;
    padding: 15px 20px;
    text-decoration: none;
}

.no-sub {
    background-color: rgb(0, 85, 69) !important;
}

.post-order {
    background-color: rgb(0, 28, 23) !important;
}

.event-2 h1 {
    text-align: center;
    font-size: 20px;
}

.events-title {
    margin-top: 50px;
    text-align: center;
    padding: 0 20px;
}

@media screen and (max-width:768px) {
    .event {
        flex-direction: column;
        margin-top: 20px;
    }

    .event-2 a {
        margin-top: 20px;

    }

    .event-2 p {
        margin-top: 20px;
    }

}