/* -------------------------------Hero---------------------------- */

.as_hero_section .as_hero_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.as_hero_section .as_hero_item.as_left,
.as_hero_section .as_hero_item.as_right {
  width: 268px;
  height: 600px;
  overflow: hidden;
}
.as_hero_section .as_hero_item.as_left .as_header_img,
.as_hero_section .as_hero_item.as_right .as_header_img {
  object-position: right;
  width: 125%;
}
.as_hero_section .as_hero_item.as_middle {
  width: calc(100vw - 600px);
  overflow: hidden;
  padding: 0 2rem;
  gap: 1.5rem;
}
.as_hero_section .as_hero_item.as_middle .as_hero_heading {
  width: 100%;
  text-align: center;
  padding: 1rem 0.2rem;
  border-bottom: 1px solid var(--as_primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.as_hero_section .as_hero_item.as_middle .as_hero_heading .as_sub_title {
  background: var(--as_yellow);
  padding: 0.3rem 0.5rem;
  border-radius: var(--as_rounded);
  color: var(--as_secondary);
}
.as_hero_section .as_hero_item.as_middle .as_hero_heading .as_header_title {
  color: var(--as_primary);
}
.as_hero_section
  .as_hero_item.as_middle
  .as_hero_heading
  .as_animated_text
  .as_heading {
  min-height: 40px;
  color: var(--as_secondary);
  position: relative;
}
.as_fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.as_fade.fade-in {
  opacity: 1;
}

/* .as_hero_section
  .as_hero_item.as_middle
  .as_hero_heading
  .as_animated_text
  .as_heading::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 100%;
  animation: blinking 1s infinite;
  margin-left: 0.5rem;
}
@keyframes blinking {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--as_secondary);
  }
  100% {
    background-color: transparent;
  }
} */
.as_hero_section .as_hero_item.as_middle .as_hero_description {
  word-wrap: break-word;
}
.as_icon_card_wrap {
  width: 100% !important;
  padding: 0 2rem;
  gap: 0.5rem;
}
.as_icon_card_wrap .as_card_item {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.as_hero_section .as_hero_item.as_middle .as_header_btn {
  background: var(--as_secondary);
  color: var(--as_white);
  padding: 0.5rem 1rem;
  border-radius: var(--as_rounded);
  transition: 0.3s ease-in-out;
}
.as_hero_section .as_hero_item.as_middle .as_header_btn:hover {
  background: var(--as_secondary_varient);
}
.as_card_icon {
  width: 60px;
  height: 60px;
}
.as_card_icon img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

/* _______________________Media_Queries_______________________ */

@media screen and (max-width: 1024px) {
  .as_hero_item {
    gap: 1rem;
  }
  .as_hero_section .as_hero_item.as_left,
  .as_hero_section .as_hero_item.as_right {
    height: fit-content;
    padding: 0.5rem;
  }
  .as_hero_section .as_hero_item.as_middle {
    width: 100%;
    order: -1;
  }
  .as_hero_section
    .as_hero_item.as_middle
    .as_hero_heading
    .as_animated_text
    .as_heading {
    min-height: 30px;
  }
}
@media screen and (max-width: 600px) {
  .as_card_icon {
    width: 40px;
    height: 40px;
  }
  .as_hero_section
    .as_hero_item.as_middle
    .as_hero_heading
    .as_animated_text
    .as_heading {
    min-height: 25px;
  }
}
@media screen and (max-width: 600px) {
  .as_hero_section .as_hero_item.as_left,
  .as_hero_section .as_hero_item.as_right {
    width: 45%;
  }
}
@media screen and (max-width: 510px) {
  .as_card_icon {
    width: 40px;
    height: 40px;
  }
  .as_hero_section
    .as_hero_item.as_middle
    .as_hero_heading
    .as_animated_text
    .as_heading {
    min-height: 50px;
  }
  .as_hero_section
    .as_hero_item.as_middle
    .as_hero_heading
    .as_animated_text
    .as_heading::after {
    height: 50%;
  }
}

@media screen and (max-width: 320px) {
  .as_hero_section .as_hero_item.as_left,
  .as_hero_section .as_hero_item.as_right {
    width: 42%;
  }
}
