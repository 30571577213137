.as_compair_section {
  flex-direction: column;
  height: 1100px;
  position: relative;
  justify-content: flex-start !important;
}
.as_table_heading {
  font-weight: bold;
  width: 100%;
  max-width: 65%;
  color: var(--as_black);
  background: var(--as_yellow);
  padding: 6px 24px 6px 24px;
  border-radius: 12px;
}
.as_compair {
  background-color: var(--as_gray);
  padding: 1rem 0;
  height: 925px;
  align-items: flex-start !important;
}
.as_compair .as_compair_wrap {
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.as_compair .as_compair_wrap .as_logo {
  width: 25%;
  display: flex;
  align-items: center;
}
.as_compair .as_compair_wrap .as_logo .as_table_logo_img {
  width: 100%;
  object-fit: cover;
}
.as_compair .as_compair_wrap .as_compair_table_wrap {
  width: 100%;
}
.as_compair .as_compair_wrap .as_compair_table_wrap .as_title {
  width: 65%;
  font-size: 1rem;
  padding: 0 1.2rem;
  height: 40px;
  color: var(--as_black);
}

.as_compair .as_compair_wrap .as_compair_table_wrap .as_cooper_kit {
  width: 17.5%;
  background: var(--as_primary);
  font-size: 1rem;
  text-align: center;
  color: var(--as_white);
}
.as_compair .as_compair_wrap .as_compair_table_wrap .as_others {
  font-size: 1rem;
  width: 17.5%;
  background: #7b3e5e;
  color: var(--as_white);
  text-align: center;
}
.as_compair .as_compair_wrap .as_compair_table_wrap .as_table_head tr .as_title,
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_head
  tr
  .as_cooper_kit,
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_head
  tr
  .as_others {
  font-size: 1.1rem;
  font-weight: 500;
}
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_head
  tr
  .as_cooper_kit,
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_head
  tr
  .as_others {
  height: 50px;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_body
  tr
  .as_title {
  background: var(--as_white);
  padding: 0 2.2rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  color: var(--as_black);
  border: 3px solid #7b3e5e;
}

.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_body
  tr
  .as_title.as_bottom,
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_body
  tr
  .as_cooper_kit.as_bottom,
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_body
  tr
  .as_others.as_bottom {
  font-size: 1.2rem;
  font-weight: 700;
}
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_body
  tr
  .as_title.as_bottom {
  padding: 0;
  background: transparent;
  border: none;
}
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_body
  tr
  .as_title.as_bottom
  .as_bottom_heading {
  background: var(--as_yellow);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  width: 100%;
  height: fit-content;
  padding: 0.5rem 2.2rem;
}
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_body
  tr
  .as_cooper_kit.as_bottom,
.as_compair
  .as_compair_wrap
  .as_compair_table_wrap
  .as_table_body
  tr
  .as_others.as_bottom {
  height: 60px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

/* _______________________Media_Queries_______________________ */
@media screen and (max-width: 650px) {
  .as_compair .as_compair_wrap {
    width: 100%;
    padding: 0 1rem;
  }
}
@media screen and (max-width: 768px) {
  .as_compair_table_wrap {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 433px) {
  .as_compair_section {
    height: 1125px;
  }
}
@media screen and (max-width: 427px) {
  .as_compair_section {
    height: 1145px;
  }
}
@media screen and (max-width: 420px) {
  .as_compair_section {
    height: 1155px;
  }
}
@media screen and (max-width: 400px) {
  .as_compair_section {
    height: 1100px;
  }
  .as_compair
    .as_compair_wrap
    .as_compair_table_wrap
    .as_table_body
    tr
    .as_title {
    font-size: 12px;
    padding: 0 0.2rem;
  }
  .as_compair .as_compair_wrap .as_compair_table_wrap .as_cooper_kit,
  .as_compair .as_compair_wrap .as_compair_table_wrap .as_others {
    font-size: 13px;
  }
  .as_compair
    .as_compair_wrap
    .as_compair_table_wrap
    .as_table_head
    tr
    .as_title,
  .as_compair
    .as_compair_wrap
    .as_compair_table_wrap
    .as_table_head
    tr
    .as_cooper_kit,
  .as_compair
    .as_compair_wrap
    .as_compair_table_wrap
    .as_table_head
    tr
    .as_others {
    font-size: 0.8rem;
  }
  .as_table_heading {
    font-size: 13px;
  }
  .as_compair
    .as_compair_wrap
    .as_compair_table_wrap
    .as_table_body
    tr
    .as_title.as_bottom
    .as_bottom_heading {
    width: 85%;
  }
  .as_compair
    .as_compair_wrap
    .as_compair_table_wrap
    .as_table_body
    tr
    .as_title.as_bottom,
  .as_compair
    .as_compair_wrap
    .as_compair_table_wrap
    .as_table_body
    tr
    .as_cooper_kit.as_bottom,
  .as_compair
    .as_compair_wrap
    .as_compair_table_wrap
    .as_table_body
    tr
    .as_others.as_bottom {
    font-size: 12px;
  }
  .as_compair .as_compair_wrap .as_logo {
    width: 15%;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }
}
