.innovative {
    text-align: center;
    padding: 60px 0px;
    height: fit-content;
    background-color: #f3e9e6;
    margin-top: 100px;
    border-radius: 30px;
    max-width: 1200px;
}

.innovative h1 {
    color: #5c2945;
    font-size: 40px;
}

.innovative p {
    font-size: 30px;
    font-weight: 400;
    color: #5c6b78;
}

.innovative-display {
    width: 92%;
    margin: 40px auto;
    display: flex;
    flex-wrap: wrap;
}

.innovative-box {
    background-color: white;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    width: 45%;
    margin: 5px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    border-radius: 33px;
    padding: 20px;
    margin-top: 10px;
    margin: 10px auto;
}

.innovative-box h1 {
    font-size: 30px;
    padding-bottom: 5px;
}

.innovative-box p {
    font-size: 16px;
}

.innovative-img {
    width: 20%;
}

.innovative-img img {
    width: 100%;
    display: block;
}

.innovative-text {
    width: 65%;
}

.innovative a {
    padding: 15px 46px;
    color: white;
    text-decoration: none;
    background-color: #ea3546;
    border-radius: 6px;
    margin-top: 40px;
}


@media screen and (max-width: 998px) {
    .innovative {
        background-color: unset;
        margin-top: 50px;
    }

    .innovative-box {
        flex-direction: column;
        justify-content: unset;
        width: 85%;
        margin: 5px auto;
        margin-top: 20px;
    }

    .innovative-img {
        width: 50%;
    }
    .innovative-text {
        padding-top: 20px;
        text-align: center;
        width: 100%;
    }
    .innovative-display {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 92%;
        margin: 20px auto 50px auto;
    }

    .innovative h1 {
        font-size: 31px;
    }

    .innovative p {
        font-size: 21px;
        margin-top: 10px;
    }
}
@media screen and (max-width: 900px) {
    .innovative {
        margin-top: 20px;
    }}

@media screen and (max-width: 600px) {
    .innovative-display {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .innovative-box {
        width: 80%;
        margin: auto;
        margin-top: 20px;
    }

    .innovative-box p {
        font-size: 13px;
    }

    .innovative-box h1 {
        font-size: 25px;
        padding-bottom: 5px;
    }
}
