.as_product {
  width: 75%;
  margin: 0 auto;
  padding: 2rem 0;
  flex-wrap: wrap;
}
.as_product .as_product_image_wrap {
  overflow: hidden;
  width: 550px;
}

.as_product .as_product_details_wrap {
  width: 530px;
  overflow: hidden;
  background-color: var(--as_white);
  box-shadow: var(--as_box_shadow);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 4rem;
  border-radius: 0.3rem;
}
.as_product .as_product_details_wrap .as_h2 {
  color: var(--as_primary);
  font-size: 1.2rem;
}
.as_product .as_product_details_wrap .as_product_plan {
  position: relative;
}
.as_product .as_product_details_wrap .as_product_plan::before,
.as_product .as_product_details_wrap .as_product_plan::after {
  position: absolute;
  font-size: 1rem;
  background: var(--as_yellow);
  padding: 0.25rem 0.6rem;
  border-radius: 35px;
}
.as_product .as_product_details_wrap .as_product_plan::before {
  content: '1';
  translate: -35px -2px;
}
.as_product .as_product_details_wrap .as_product_plan::after {
  content: '2';
  translate: -35px -55px;
}
.as_product .as_product_details_wrap .as_product_plan .as_pick_a_plan {
  color: var(--as_secondary);
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.as_product .as_product_details_wrap .as_product_plan .as_p {
  font-size: 0.9rem;
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 0;
  gap: 1.2rem;
  text-align: center;
  position: relative;
}
.as_left_border {
  width: 2px;
  max-height: 300px;
  background: yellow;
  position: absolute;
  left: -23px;
  top: 24px;
  height: 75%;
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap
  .as_extra {
  width: 225px;
  height: 50px;
  border-bottom: 1px solid var(--as_yellow);
  border-left: 1px solid var(--as_yellow);
  border-right: 1px solid var(--as_yellow);
  position: absolute;
  translate: 0 40px;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap
  .as_extra::before {
  content: '+ Free Extra Kit';
  background-color: var(--as_white);
  padding: 0.15rem 0.5rem;
  color: var(--as_white);
  background-color: #0092ee;
  border-radius: var(--as_rounded);
  font-size: 10px;
  font-weight: bold;
  translate: 0 26px;
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap
  .as_product_plan_item.as_selected {
  border: 1px solid #f6e4df;
  box-shadow: var(--as_box_shadow);
  background-color: #f6e4df;
  color: var(--as_black);
  transition: 1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap
  .as_product_plan_item {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 185px;
  padding: 0.5rem 0;
  border-radius: 0.3rem;
  position: relative;
  background: var(--as_white);
  cursor: pointer;
  border: 1px solid #00000025;
  z-index: 10;
  transition: 1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap
  .as_product_plan_item::before {
  content: 'Savings of $20';
  color: var(--as_secondary);
  position: absolute;
  font-size: 11px;
  border-radius: 0.2rem;
  padding: 0.2rem 2rem;
  font-weight: bold;
  background-color: var(--as_yellow);
  width: -moz-fit-content;
  width: fit-content;
  translate: 15px -25px;
  z-index: 0;
  letter-spacing: 1px;
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap
  .as_product_plan_item.as_item2:before {
  content: 'Savings of $40';
  padding: 0.2rem 1rem;
  translate: 32px -25px;
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap
  .as_product_plan_item
  .as_h4 {
  font-size: 0.8rem;
  line-height: 18px;
}
.as_product
  .as_product_details_wrap
  .as_product_plan
  .as_product_plan_item_wrap
  .as_product_plan_item
  small {
  font-size: 0.7rem;
}
.as_product .as_product_details_wrap .as_button {
  border: 1px solid var(--as_primary);
  border-radius: 0.3rem;
  color: var(--as_primary);
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-self: center;
  align-items: center;
  padding: 0.6rem 1.2rem;
  background: var(--as_white);
}
.as_product .as_product_details_wrap .as_button.active {
  border: 1px solid var(--as_primary);
  background-color: var(--as_primary);
  color: var(--as_white);
  transition: 0.3s ease-in-out;
}
.as_product .as_product_details_wrap .as_button svg {
  fill: var(--as_primary);
}
.as_product .as_product_details_wrap .as_button.active svg {
  fill: var(--as_white);
  transition: 0.3s ease-in-out;
}
.as_product .as_product_details_wrap .as_button.as_add_to_cart {
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  color: var(--as_white);
  background: var(--as_secondary);
  text-decoration: none;
}
.as_product .as_product_details_wrap .as_button.as_add_to_cart:hover {
  background: var(--as_secondary_varient);
}
/* _______________________Media_Queries_______________________ */

@media screen and (max-width: 550px) {
  .as_product .as_product_image_wrap {
    width: 100%;
    padding: 1rem;
  }

  .as_product .as_product_details_wrap {
    width: 100%;
    padding: 1rem 3rem;
  }
}
@media screen and (max-width: 485px) {
  .as_left_border {
    height: 78%;
  }
  .as_product
    .as_product_details_wrap
    .as_product_plan
    .as_product_plan_item_wrap
    .as_extra {
    border: none;
  }
  .as_product
    .as_product_details_wrap
    .as_product_plan
    .as_product_plan_item_wrap
    .as_extra::before {
    translate: 0 -100px;
  }
  .as_product .as_product_details_wrap .as_button {
    font-size: 12px;
    width: 97%;
    justify-content: center;
  }
  .as_product .as_product_details_wrap .as_button.as_add_to_cart {
    width: 80%;
  }
}
@media screen and (max-width: 433px) {
  .as_left_border {
    height: 100%;
    max-height: 330px;
  }
}
@media screen and (max-width: 390px) {
  .as_left_border {
    max-height: 350px;
  }
}
@media screen and (max-width: 368px) {
  .as_left_border {
    max-height: 390px;
  }
  .as_product
    .as_product_details_wrap
    .as_product_plan
    .as_product_plan_item_wrap {
    gap: 2.5rem;
  }
}
@media screen and (max-width: 300px) {
  .as_left_border {
    max-height: 385px;
  }
}
@media screen and (max-width: 284px) {
  .as_left_border {
    max-height: 385px;
  }
}
