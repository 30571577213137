.navbar-main {
    width: 100%;
    height: 76px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 2px solid #e5e5e5;
    z-index: 999999999;
}

.desktop-links {
    width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 0px 0 0 50px !important; */
}

.desktop-links a {
    text-decoration: none;
    color: #1111117a;
    font-weight: 600;
    font-size: 15px;
    /* padding: 0 10px; */
    margin: 0 0 0 25px;
    font-family: 'Uniform';
}

.desktop-links a:hover {
    color: #000000;
    text-decoration: underline;
    text-decoration-color: #601b3a;

    text-decoration-thickness: 3px;
    text-underline-offset: 27px;
}

.mobile-navbar {
    padding-top: 50px;
}

.mobile-navbar a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 5px 10px;
    margin: 15px 0px;

    font-family: 'Uniform';
}

.get-started {
    background-color: #faa019;
    color: white !important;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    font-size: 16px !important;
    align-items: center;
    justify-content: space-between;
    text-decoration: none !important;
    padding: 10px 15px !important;
}

.get-started svg {
    margin-right: 5px;
    color: #601b3a;
}

.mobile-navbar {
    display: none;
}

.burger-icon {
    margin: 0 10px;
    display: none;
}

.focus {
    color: #000000 !important;
    text-decoration: underline !important;
    text-decoration-color: #601b3a !important;

    text-decoration-thickness: 3px !important;
    text-underline-offset: 27px !important;
}

.navbar-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .navbar-main {
        justify-content: center;
    }

    .desktop-links a {
        font-size: 16px;
    }

    .navbar-logo {
        padding: 0 0px 0 20px;
    }

    .desktop-links {
        padding: 0 20px 0 0;
    }
}

@media screen and (max-width: 998px) {
    .desktop-links {
        display: none;
    }

    .burger-icon {
        margin: 0 30px;
        display: block;
    }

    .mobile-navbar {
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        position: absolute;
        background-color: #5c2946;
        top: 312px;

        z-index: 9999999;
        height: calc(100vh - 78px);
    }

    .get-started svg {
        margin-right: 20px;
        color: white;
    }

    .navbar-logo {
        padding-left: 30px;
        width: 130px;
    }
}

@media screen and (max-width: 320px) {
    .burger-icon {
        margin: 0 30px 0 0;
    }
}
