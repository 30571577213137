.about-page {
  background-color: white;
}

.about-firstSection {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-firstSection h1 {
  font-size: 40px;
  color: #601b3a;
  text-align: center;
  width: 60%;
  margin: 100px 0 0;
}

.about-firstSection-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.about-firstSection-text h1 {
  font-size: 50px;
  text-align: left;
  width: 50%;
  margin: 0;
}

.about-firstSection-text h1>span {
  color: #5c6b78;
  font-size: 30px;
}

.about-firstSection-text p {
  width: 50%;
  color: #000000;
}

.about-firstSection img {
  margin-top: 80px;
  width: 100%;
}

.about-secondSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 0 100px;
  height: auto;
}

.about-secondSection p {
  color: #5C6B78;
  font-size: 25px;
}

.about-secondSection h1 {
  color: #601b3a;
  font-size: 30px;
  margin-bottom: 10px;
}

.about-secondSection img {
  width: 40%;
}

/* .our-vision-img{
  margin-top: -100px;
} */

.about-thirdSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -60px 0 100px;
}

.about-thirdSection h1 {
  color: #601b3a;
  font-size: 30px;
  width: 400px;
  text-align: center;
}

.about-thirdSection p {
  text-align: center;
  width: 55%;
  /* margin-top: 15px; */
}

.about-cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.about-card {
  width: 100%;
  height: 390px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-repeat: no-repeat;
  position: relative;
}

.about-card-text p {
  color: black;
  text-align: center;
  width: 100%;
  font-weight: 400;
}

.about-card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #F6F7F8;
  border-radius: 9px;
  margin-top: 10px;
  height: 100px;
}

.card-number {
  width: 50px;
  height: 50px;
  background-color: #5C2945;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  position: absolute;
  border-radius: 9px;
  left: 5px;
  bottom: 5px;

}

/* .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 40px !important;
  left: 0;
  width: 100%;
} */
.first-banner-image-about {
  width: 100%;
  height: 500px;
  /* background-image: url(../Assets//About/firstBannerImg.png); */
  background-size: cover;
  border-radius: 40px;
  margin-top: 50px;
}

.about-fourthSection {
  width: 100%;
  height: auto;
  margin: 100px 0 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.about-fourthSection h1 {
  font-size: 50px;
  color: #5c6b78;
  width: 50%;
}

.about-fourthSection h1>span {
  font-size: 30px;
  color: #601b3a;
}

.about-fourthSection div {
  width: 50%;
}

.about-fourthSection p {
  width: 100%;
  color: #000000;
  margin: 0 0 0 auto;
}

.about-fifthSection {
  width: 100%;
  margin: 20px 0 40px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 3px solid #5c2945;
  border-radius: 40px;
}



.a5-title {
  position: absolute;
  top: -32px;
  color: #601b3a;
  font-size: 50px;
  background-color: white;
  width: 320px;
  text-align: center;
  font-weight: bold;
}

.about-fifthSection p {
  color: #5c6b78;
  padding: 0 200px;
  text-align: center;
  font-size: 20px;
}

.about-sixthSection {
  width: 100%;
  height: auto;
  margin: 100px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-sixthSection h1 {
  color: #f2b21c;
  font-size: 30px;
}

.about-sixthSection img {
  margin-top: 20px;
}

.about-sixthSection p {
  color: #5c6b78;
  text-align: center;
  width: 50%;
  font-size: 20px;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
}

.about-sixthSection span {
  color: #5c2945;
  font-weight: bold;
}

.about-seventhSection {
  width: 100%;
  height: auto;
  margin: 100px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
}

.about-seventhSection-text {
  width: 50%;
}

.about-seventhSection-text h1 {
  color: #601b3a;
  font-size: 30px;
}

.about-seventhSection-text p {
  font-size: 20px;
  margin: 30px 0;
}

.about-seventhSection-text a {
  text-decoration: none;
  font-family: "Uniform";
  font-weight: 600;
  font-size: 20px;
  padding: 15px 35px;
  border-radius: 4px;
  margin-right: 5px;
  /* margin: 0; */
  background-color: #90dae5;
  color: white;
}

.about-cards-desktop {
  display: flex;
}

.about-cards-mobile {
  display: none;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  color: black;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
  color: black;
}

.about-mySwiper {
  height: 100%;
}

.aboutCards {
  width: 390px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width:1300px) {
  .aboutCards {
    width: 350px;
  }

  .about-card {
    height: 350px;
  }

  .first-banner-image-about {
    width: 95%;
  }

  .about-firstSection-text {
    width: 95%;
  }

  .about-cards {
    width: 95%;
  }

  .about-fourthSection {
    width: 95%;
    margin: 100px auto 100px;
  }

  .about-fifthSection {
    width: 95%;
    margin: 20px auto 40px;
  }

  .about-seventhSection {
    width: 95%;
    margin: 100px auto 0;
  }

  .about-seventhSection-text {
    width: 45%;
  }

}

@media screen and (max-width:1130px) {

  .aboutCards {
    width: 300px;
  }

  .about-card {
    height: 300px;
  }
}

@media screen and (max-width: 998px) {
  .aboutCards {
    width: 350px;
    height: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  /* .our-vision-img{
    margin-top: -50px;
  } */
  .about-card-text {
    margin-bottom: -65px;
  }

  .about-secondSection {
    margin: 100px 0 65px;
  }

  .first-banner-image-about {
    width: 95%;
    height: 300px;
    background-image: url(../Assets//About/firstBannerImg.png);
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin-top: 50px;
  }

  .about-sixthSection img {
    width: 95%;
  }

  .about-firstSection img {
    width: 95%;
  }

  .about-fourthSection {
    width: 100%;
    height: auto;
    margin: -100px 0 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .about-firstSection h1 {
    margin-top: 50px;
    width: 90%;
    font-size: 31px;
  }

  .about-firstSection-text {
    flex-direction: column;
    margin-top: 45px;
  }

  .about-firstSection-text h1 {
    font-size: 31px;
    text-align: center;
  }

  .about-secondSection h1 {
    font-size: 29px;
    padding: 0 10px;
    text-align: center;
  }

  .about-firstSection-text h1>span {
    font-size: 15px;
  }

  .about-thirdSection h1 {
    font-size: 31px;
    width: 90%;
  }

  .about-firstSection-text p {
    width: 90%;
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
  }

  .about-secondSection img {
    max-width: 350px;
    width: 90%;
  }

  .about-thirdSection p {
    width: 90%;
    font-size: 18px;
  }

  .about-cards-desktop {
    display: none;
  }

  .about-cards-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 500px;
  }

  .about-card {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    /* margin: 0 auto; */
    /* border-radius: 10px; */
    /* box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.41);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.41);
    margin-top: 10px; */
  }

  .about-fourthSection {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about-fourthSection h1 {
    width: 90%;
    font-size: 31px;
  }

  .about-fourthSection h1>span {
    font-size: 15px;
  }

  .about-fourthSection p {
    width: 90%;
    margin-top: 20px;
    font-size: 18px;
  }

  .about-fifthSection {
    width: 90%;
    margin: 0 auto;
  }

  .about-fifthSection p {
    padding: 0 20px;
    font-size: 18px;
  }

  .about-sixthSection h1 {
    color: #f2b21c;
    font-size: 31px;
  }

  .a5-title {
    top: -18px;
    font-size: 28px;
    width: 220px;
  }

  .about-sixthSection p {
    width: 90%;
    font-size: 18px;
  }

  .about-seventhSection {
    /* height: 700px; */
    flex-direction: column-reverse;
    margin: 0;
    padding-bottom: 50px;
  }

  .about-seventhSection img {
    width: 95%;
  }

  .about-seventhSection-text {
    width: 90%;
    text-align: center;
    margin-top: 50px;
  }

  .about-seventhSection-text h1 {
    font-size: 31px;
  }

  .about-seventhSection-text p {
    font-size: 18px;
  }
}

@media screen and (max-width:600px) {
  .our-vision-arrows-img {
    display: none;
  }

  .our-vision-img {
    margin-top: 0px;
  }

  .first-banner-image-about {
    height: 120px;
  }

}