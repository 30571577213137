.first-f2018 {
    width: 100%;
    height: 550px;
    background-image: linear-gradient(rgb(187, 128, 143), rgb(187, 128, 143)) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.first-f2018 h1 {
    font-size: 40px;
    color: rgb(0, 0, 0);
    font-style: italic;
    max-width: 1200px;
    width: 95%;
    text-align: center;

}

.first-f2018 p {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 400;
    color: rgb(255, 255, 255);
    margin: 20px 0;
}

.second-f2018 {
    width: 100%;
    padding: 40px 0;
    background-image: linear-gradient(rgb(242, 222, 227), rgb(242, 222, 227));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.second-f2018 h1 {
    font-size: 24px;
    font-weight: 400;
    color: rgb(20, 41, 84);
}

.second-f2018 p {
    max-width: 1200px;
    font-size: 24px;
    margin: 30px 0;
}

.second-2-f2018 {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    align-items: center;
}

.second-2-f2018-text {
    display: flex;
    flex-direction: column;
}

.second-2-f2018-text h1 {
    text-align: right;
    padding: 0 30px;
}

.second-2-f2018-text p {
    padding: 0 40px;
}

.third-f2018 {
    /* max-width: 1200px; */
    width: 100%;
    padding: 40px 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    text-align: center;
}

.third-f2018 h2 {
    margin-top: 30px;

}

.third-f2018 p {
    max-width: 1200px;
    width: 95%;
    margin-top: 30px;
}

.f2018-main-card {
    width: 95%;
    max-width: 1200px;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.f2018-card {

    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    margin: 0 auto;
}

.f2018-card img {
    width: 250px;
    height: 250px;
}

.f2018-card p {
    width: 100%;
    padding: 0 50px;
}

.fifth-f2018 {
    width: 100%;
    display: flex;
    padding: 100px 0;
    background-image: linear-gradient(rgb(242, 222, 227), rgb(242, 222, 227));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fifth-f2018 p {
    max-width: 1200px;
    width: 95%;
}

.sixth-f2018 {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    width: 100%;

}

.sixth-f2018-main-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;

}

.sixth-f2018-card {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sixth-f2018-card-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}



.sixth-f2018-card-header p {
    font-size: 22px;
    margin: 0;
}

.sixth-f2018-card img {
    width: 100%;
    margin-top: 20px;
    height: 400px;
}

.sixth-f2018-card-header img {
    width: 100px;
    height: 100px;
}

.button-to-top {
    padding: 17px;
    background-color: #e4e4e4;
    text-decoration: none;
    color: #575a51;
    margin-top: 50px;
    outline: none;
    border: none;
    width: 315px;
    font-size: 15px;
    font-weight: 600;

}

.button-to-top:hover,
.f2018-button1:hover {
    background-color: #1abc9c;
    color: white;
}

.f2018-button1 {
    margin-top: 50px;
    padding: 17px;
    background-color: rgb(87, 90, 81);
    color: white;
    text-decoration: none;
    border: 1px solid rgb(26, 188, 156);
}

.f2018-button2 {
    background-color: rgb(30, 79, 146);
}

.f2018-button3 {
    background-color: #174d35;
}

.button-to-top2 {
    color: #1e4f92;
}

.button-to-top3 {
    color: #174d35;
}

.f2018-show-div {
    width: 285px;
    padding: 15px;
    background-color: #e4e4e4;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.f2018-show-div p {
    color: #575a51;
}

.check-img {
    width: 15px !important;
    height: 15px !important;
    margin-right: 15px !important;
}

.f2018-show-div ul {
    list-style: none;
}

.f2018-show-div li {
    color: #575a51;
    margin: 20px 0;
}

.f2018-show-div-img {
    width: 100% !important;
    height: 150px !important;
}

.f2018-show-div3 li {
    color: #174d35;
}

.f2018-show-div2 li {
    color: #1e4f92;
}

.f2018-footer {
    width: 100%;
    height: 120px;
    background-color: #F2DEE3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.f2018-footer img {
    height: 60px;
}


@media screen and (max-width:1200px) {
    .second-f2018 p {
        padding: 0 40px;
    }

    .second-2-f2018 img {
        padding-left: 20px;
    }

    .f2018-main-card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .sixth-f2018-card {
        width: 300px;
    }

    .sixth-f2018-card img {
        height: 320px;
    }

    .sixth-f2018-card-header img {
        width: 100px;
        height: 100px;
    }

    .sixth-f2018-main-card {

        width: 95%;
    }

    .f2018-show-div-img {
        width: 100% !important;
        height: 150px !important;
    }

}

@media screen and (max-width:998px) {
    .third-f2018 img {
        width: 95%;
        margin: 0 auto;
    }

    .first-f2018 img {
        width: 95%;
    }

    .sixth-f2018-main-card {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 50px 0;
    }

    .sixth-f2018-card {
        margin: 0 auto;
    }

    .second-2-f2018 {
        flex-direction: column;
    }

    .second-2-f2018-text h1 {
        text-align: center;
        padding: 0 30px;
    }

    .second-2-f2018-text {
        display: flex;
        margin-top: 40px;
    }

    .second-f2018 p {
        padding: 0 40px;
        text-align: center;
        font-size: 20px;
    }

    .f2018-main-card {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .first-f2018 p {
        text-align: center;
        padding: 0 30px;
    }

    .sixth-f2018-card-header img {
        width: 100px !important;
        height: 100px !important;
    }
}