.white-background {
    background-color: white;
}

.thrv_heading {
    color: #8c384b;
    margin: 20px 0;
    padding: 40px 0;
}

.tve_image {
    /* width: 180px; */
    margin: 0 auto;
}

.thrv_wrapper.tve_image_caption {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
}

.thrv_text_element p {
    color: #5d5d5d;
    margin: 14px auto;
    max-width: 1200px;
    text-align: left;
    padding: 0 20px;
}

.tcb-flex-row.tcb--cols--3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 60px;
}

.tcb-flex-col {
    width: 320px;
    margin: 0 auto;
}

.tcb-col.tve_empty_dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.thrv-button {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(26, 188, 156);
    color: rgb(26, 188, 156) !important;
    background-color: rgb(140, 56, 75) !important;
    background-image: none !important;
    text-decoration: none !important;
    color: white !important;
    padding: 12px 15px;
}

.tcb-button-link {
    color: white;
    text-decoration: none;
}

.thrv_wrapper.thrv-columns {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.tcb-flex-row.tcb--cols--2 {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 0 auto;
}

img.tve_image.wp-image-13837.colorbox-15461,
img.tve_image.wp-image-13839.colorbox-15461 {
    width: 300px;
    height: 300px;
}

img.tve_image.wp-image-14143.colorbox-15461 {
    width: 300px;
    height: 340px;
    margin-top: -55px;
}

img.tve_image.wp-image-12350.colorbox-15461 {
    width: 180px;
    height: 90px;
}

@media screen and (max-width:1200px) {
    .tcb-flex-col {
        width: 320px;
        margin: 0 auto;
    }

}

@media screen and (max-width:998px) {
    .tcb-flex-row.tcb--cols--2 {
        display: flex;
        flex-direction: column;

    }

    .tcb-flex-row.tcb--cols--3 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 100px 0;
    }

}