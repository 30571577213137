.selected-country {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}

.country-dropdown {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 400px;
}

.country-dropdown h1 {
    color: #8c384b;
    font-size: 21px;
    text-align: right;

}

.country-dropdown p {
    color: #5d5d5d;
    font-size: 14px;
    margin: 30px 0;
    align-self: flex-start;

}

.country-dropdown a {
    color: #1193bb;
}

.country-image {
    width: 50%;
}

.country-image img {
    width: 400px;
    height: 400px;
}

.dropdown-form {
    align-self: flex-start;
}

.country-select-dropdown {
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgb(144, 218, 229);
    background-color: #90dae540;
    outline: none;
    color: #7b3d5d;
}



@media screen and (max-width:998px) {
    .selected-country {

        flex-direction: column;

    }

    .country-dropdown {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 280px;
    }

    .country-image {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .country-dropdown {
        width: 100%;
    }

    .country-dropdown p {
        align-self: center;
        text-align: center;
        padding: 0 20px;

    }

    .dropdown-form {
        align-self: center;
    }

    .country-dropdown h1 {
        text-align: center;
        padding: 0 20px;

    }


}

@media screen and (max-width:400px) {
    .country-image img {
        width: 100%;
    }

}