.as_abouts_wrap {
  margin: 0 auto;
  padding: 2rem 30%;
}
.as_abouts_wrap .ass_abouts_image {
  width: 500px;
}
.as_abouts_wrap .ass_abouts_image .as_about_img {
  border-radius: 0.5rem;
}
.as_abouts_wrap .as_abouts_content {
  /* width: 500px; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4%;
  margin-bottom: 10%;
}
.as_abouts_wrap .as_abouts_content .as_about_heading {
  color: var(--as_secondary);
}
.as_abouts_wrap .as_abouts_content .as_about_desc {
  color: var(--as_black-varient);
}
.as_abouts_wrap .as_abouts_content .as_about_button {
  color: var(--as_white);
  background: var(--as_secondary);
  padding: 0.5rem 1.2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.3rem;
  transition: 0.3s ease-in-out;
}
.as_abouts_wrap .as_abouts_content .as_about_button:hover {
  background: var(--as_secondary_varient);
}

/* _______________________Media_Queries_______________________ */

@media screen and (max-width: 600px) {
  .as_abouts_wrap {
    width: 90%;
    padding: 2rem 0.5rem;
  }
}
