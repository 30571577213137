.as_home_header .swiper-button-prev,
.as_home_header .swiper-button-next {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: var(--as_secondary);
  top: 35% !important;
}
.as_home_header .swiper-button-prev {
  left: 19% !important;
}
.as_home_header .swiper-button-next {
  right: 19% !important;
}
.as_home_header .swiper-button-next::after,
.as_home_header .swiper-button-prev::after {
  font-size: 22px;
  color: var(--as_white) !important;
}

.as_home_header_slides.swiper-slide-active {
  opacity: 1;
}
.as_home_header_slides {
  opacity: 0.5 !important;
}
@media screen and (max-width: 900px) {
  .as_home_header .swiper-button-prev {
    left: 1rem !important;
  }
  .as_home_header .swiper-button-next {
    right: 1rem !important;
  }
  .as_home_header .swiper-button-prev,
  .as_home_header .swiper-button-next {
    top: 45% !important;
  }
}
@media screen and (max-width: 730px) {
  .as_home_header .swiper-button-prev {
    left: 0.5rem !important;
  }
  .as_home_header .swiper-button-next {
    right: 0.5rem !important;
  }
  .as_home_header .swiper-button-prev,
  .as_home_header .swiper-button-next {
    top: 45% !important;
  }
}
@media screen and (max-width: 500px) {
  .as_home_header .swiper-button-prev,
  .as_home_header .swiper-button-next {
    width: 30px;
    height: 30px;
  }
  .as_home_header .swiper-button-next::after,
  .as_home_header .swiper-button-prev::after {
    font-size: 14px !important;
  }
}
