/*_______________________Testimonials_______________________*/

.as_testimonials {
  width: 100vw;
  overflow: hidden;
  text-align: center;
}

/*_______________________Brand Logos_______________________*/

.as_brand {
  flex-direction: column;
  padding: 1rem 0;
}
.as_brand_wrap {
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem 0;
}
.as_brand_item {
  width: 90px;
  height: 90px;
  padding: 0.5rem;
  box-shadow: var(--as_box_shadow);
  border-radius: var(--as_rounded);
}
.as_testimonials .as_testimonials_wrap {
  background-color: var(--as_gray);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
}
.as_testimonials .as_testimonials_heading .as_heading {
  color: var(--as_primary);
}
.as_testimonials .as_testimonials_items_wrap {
  gap: 2rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}
.as_testimonials .as_testimonials_items_wrap .as_testimonials_item {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  background-color: var(--as_white);
  padding: 1.2rem 0.5rem;
  border-radius: var(--as_rounded);
}
.as_testimonials
  .as_testimonials_items_wrap
  .as_testimonials_item
  .as_icons_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.as_testimonials
  .as_testimonials_items_wrap
  .as_testimonials_item
  .as_icons_wrap
  .as_icon {
  width: 100%;
  object-fit: cover;
}
.as_testimonials2
  .as_testimonials_items_wrap
  .as_testimonials_item
  .as_testimonial_message {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*_______________________Promises_______________________*/

.as_promises_wrap {
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 0;
  align-items: center;
  justify-content: center;
}
.as_promises_wrap .as_promises_heading {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.as_promises_wrap .as_promises_heading .as_p {
  color: var(--as_secondary);
}
.as_promises_wrap .as_promises_heading .as_sub_heading {
  color: var(--as_primary);
}
.as_promises_wrap .as_promise_item_wrap {
  gap: 1rem;
  margin: 1rem 0;
  text-align: left;
  justify-content: flex-start;
  width: 70%;
}
.as_promises_wrap .as_promise_item_wrap .as_promise_item {
  width: 225px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.as_promises_wrap .as_promise_item_wrap .as_promise_item .as_p {
  color: var(--as_secondary);
  font-weight: 400;
  flex: 1;
}
.as_promises_wrap .as_promise_item_wrap .as_promise_item .as_icon_wrap {
  width: 40px;
  height: 40px;
  background: #f99830;
  overflow: hidden;
  padding: 0.8rem;
  border-radius: 50%;
}
@media screen and (max-width: 1200px) {
  .as_promises_wrap .as_promise_item_wrap .as_promise_item {
    width: 30%;
  }
}
@media screen and (max-width: 720px) {
  .as_promises_wrap .as_promise_item_wrap .as_promise_item {
    width: 45%;
  }
  .as_promises_wrap .as_promise_item_wrap .as_promise_item .as_icon_wrap {
    width: 20px;
    height: 20px;
  }
  .as_promises_wrap .as_promise_item_wrap {
    width: 90%;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .as_promises_wrap .as_promise_item_wrap .as_promise_item .as_p {
    flex: 1;
    font-size: 10px;
  }
}
